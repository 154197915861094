<script setup lang="ts">
import { useRouter } from 'vue-router';
import LForm from '@/packages/form/components/LForm.vue';
import { LFormCode } from '@/packages/form/components/l-form-code.enum';
import { useLearnerStore } from '../store/learner.store';
import { t } from '@/plugins/i18n';
import LPage from '@/core/components/LPage.vue';

const learnerStore = useLearnerStore();
const router = useRouter();

const completeFormHandler = async () => {
    await learnerStore.fetch();
    await router.push('/trial-catalog');
};
</script>

<template>
    <l-page>
        <l-form
            :code="LFormCode.LEARNER_ONBOARDING"
            :next-step-btn-text="t('learner.onboarding.form.nextStep')"
            :complete-survey-btn-text="t('learner.onboarding.form.complete')"
            :oncomplete="completeFormHandler"
        />
    </l-page>
</template>
