import { t } from '@/plugins/i18n';

export function getYearWord(num: number) {
    const lastDigit = Math.abs(num) % 100;
    const lastSingleDigit = lastDigit % 10;

    if (lastDigit > 10 && lastDigit < 20) {
        return `${num} ${t('yearForms.many')}`;
    }

    if (lastSingleDigit === 1) {
        return `${num} ${t('yearForms.singular')}`;
    }

    if (lastSingleDigit >= 2 && lastSingleDigit <= 4) {
        return `${num} ${t('yearForms.few')}`;
    }

    return `${num} ${t('yearForms.many')}`;
}
