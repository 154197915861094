<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import { IonButton } from '@ionic/vue';
import { Color } from '@ionic/core';

const props = defineProps<{
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    expand?: 'full' | 'block' | undefined;
    fill?: 'clear' | 'outline' | 'solid' | 'default';
    size?: 'default' | 'small' | 'large';
    color?: Color;
}>();

const emit = defineEmits<{
    (e: 'click', event: MouseEvent): void;
}>();

const defaultFill = 'default';
const defaultColor = 'primary';
</script>

<template>
    <ion-button
        :type="props.type || 'button'"
        :size="props.size || 'default'"
        :disabled="props.disabled || false"
        :expand="props.expand"
        :fill="props.fill || defaultFill"
        :color="props.color || defaultColor"
        @click="$emit('click', $event)"
    >
        <slot />
    </ion-button>
</template>

<style scoped></style>
