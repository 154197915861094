<script setup lang="ts">
import { Ref, ref } from 'vue';
import { t } from '@/plugins/i18n';
import { fromZonedToLocal } from '@/core/utils/date-formatters';
import { calendarApi, cancelReasonOptionsValues } from '../../api/calendar.api';
import {
    IonCard,
    IonCardContent,
    IonRadio,
    IonButton,
    IonSelect,
    IonInput,
    IonSelectOption,
    IonRadioGroup,
} from '@ionic/vue';
import { useCancelReasonOptions } from '../../utils/cancel-reason-options';
import LButton from '@/core/components/LButton.vue';
import LSelect from '@/core/components/LSelect.vue';

const emit = defineEmits(['ok']);

const props = defineProps({
    event: {
        type: Object,
        required: true,
    },
    timezone: {
        type: String,
        required: true,
    },
});

const cancelReason = ref('client_request') as Ref<cancelReasonOptionsValues>;
const reasonDescription = ref('');
const isCancelRecurrent = ref(false);

const { cancelReasonOptions } = useCancelReasonOptions();

const onSubmit = async () => {
    const body = {
        startTime: fromZonedToLocal(new Date(props.event.startTime), props.timezone),
        reasonType: cancelReason.value,
        reasonDescription: reasonDescription.value,
        isCancelRecurrent: isCancelRecurrent.value,
    };

    await calendarApi.cancelEvent(props.event.uid, body as unknown as any);

    emit('ok');
};
</script>

<template>
    <ion-card>
        <ion-card-content>
            <div>
                {{ t('tutor.calendar.cancel.startDate') }}
                {{ new Date(props.event.startTime).toLocaleString() }}
            </div>
            <l-select
                :label="t('tutor.calendar.cancel.cancelReason')"
                v-model:model-value="cancelReason"
                :options="cancelReasonOptions"
            >
            </l-select>
            <div>
                {{ t('tutor.calendar.cancel.cancelComment') }}
                <ion-input fill="outline" v-model:model-value="reasonDescription" />
            </div>
            <div v-if="props.event.isRecurrent && !props.event.isException">
                <div>{{ t('tutor.calendar.cancel.recurrency') }}</div>
                <ion-radio-group :value="isCancelRecurrent">
                    <ion-radio label-placement="end" :value="false">{{
                        t('tutor.calendar.cancel.cancelOnce')
                    }}</ion-radio
                    ><br />
                    <ion-radio label-placement="end" :value="true">{{
                        t('tutor.calendar.cancel.cancelAll')
                    }}</ion-radio
                    ><br />
                </ion-radio-group>
            </div>

            <l-button unelevated type="submit" @click="onSubmit">
                {{ t('tutor.calendar.cancel.cancelButton') }}
            </l-button>
        </ion-card-content>
    </ion-card>
</template>

<style scoped></style>
