import Cookies from 'js-cookie';

export const roleGuard = (to) => {
    const role = Cookies.get('role');
    if (to.path === '/') {
        if (role === 'learner') {
            return '/learner';
        }
        if (role === 'tutor') {
            return '/tutor';
        }
    }

    return true;
};
