import { paths } from '@client';
import { client } from '@/core/api/client-proxy';

export type Pagination = paths['/tutor/forms/{code}']['get']['parameters']['query'];

export type TutorCalendarEvent =
    paths['/tutor/events/']['post']['responses']['200']['content']['application/json']['event'];

export type cancelReasonOptionsValues =
    paths['/tutor/events/{uid}/cancel']['post']['requestBody']['content']['application/json']['reasonType'];
export type rescheduleReasonOptionsValues =
    paths['/tutor/events/{uid}/reschedule']['post']['requestBody']['content']['application/json']['reasonType'];

export type WorkHour =
    paths['/tutor/work-hours/']['post']['requestBody']['content']['application/json']['workHours'][0];

export type AvailableSlot =
    paths['/tutor/available-slots/']['get']['responses']['200']['content']['application/json']['data']['0'];

export type ScheduleEventDto =
    paths['/tutor/events/']['post']['requestBody']['content']['application/json'];

type CancelEventDto =
    paths['/tutor/events/{uid}/cancel']['post']['requestBody']['content']['application/json'];
type TransferEventDto =
    paths['/tutor/events/{uid}/reschedule']['post']['requestBody']['content']['application/json'];

export class CalendarApi {
    async getCalendarEvents(dto: { startTime: string; endTime: string }) {
        const { data } = await client.GET('/tutor/events/', {
            params: {
                query: {
                    ...dto,
                },
            },
        });
        return data!;
    }

    async getCalendarWorkHours() {
        const { data } = await client.GET('/tutor/work-hours/');
        return data!;
    }

    async postCalendarWorkTimes(workHours: WorkHour[]) {
        await client.POST('/tutor/work-hours/', {
            body: {
                workHours,
            },
        });
    }

    async getAvailableSlots(dto: { startTime: string; endTime: string }) {
        const { data } = await client.GET('/tutor/available-slots/', {
            params: {
                query: {
                    ...dto,
                },
            },
        });
        return data!;
    }

    async scheduleEvent(dto: ScheduleEventDto) {
        await client.POST('/tutor/events/', {
            body: {
                ...dto,
            },
        });
    }

    async cancelEvent(uid: string, dto: CancelEventDto) {
        await client.POST('/tutor/events/{uid}/cancel', {
            params: {
                path: {
                    uid,
                },
            },
            body: {
                ...dto,
            },
        });
    }

    async transferEvent(uid: string, dto: TransferEventDto) {
        await client.POST('/tutor/events/{uid}/reschedule', {
            params: {
                path: {
                    uid,
                },
            },
            body: {
                ...dto,
            },
        });
    }
}

export const calendarApi = new CalendarApi();
