import { defineStore } from 'pinia';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { Learner, useLearnerApi } from '../api/learner.api';

const learnerApi = useLearnerApi();

export const useLearnerStore = defineStore('learner', {
    state: () => ({
        profile: {} as Learner,
    }),
    getters: {
        isOnboardingForm: (state) => {
            return state.profile.learner?.status === 'onboarding.wait_submit_form';
        },
        isScheduleTrial: (state) => {
            return state.profile.learner?.status === 'onboarding.wait_schedule_trial_lesson';
        },
    },
    actions: {
        async fetch() {
            const learner = await learnerApi.getLearnerProfile();
            this.profile = learner;
            useAuthStore().setAuthUser(learner.user);
        },
    },
});
