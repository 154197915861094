<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import { IonItem, IonLabel, IonList, IonListHeader } from '@ionic/vue';
import { onMounted, ref } from 'vue';
import { catalogApi, Course } from '../api/catalog.api';
import { useI18n } from 'vue-i18n';

const courses = ref([] as Course[]);

async function fetchData() {
    courses.value = await catalogApi.getCourses();
}

const { t } = useI18n();

onMounted(() => {
    fetchData();
});
</script>

<template>
    <l-page>
        <ion-list>
            <ion-list-header>
                <!-- TODO i18n -->
                <ion-label>{{ t('tutor.materials.title') }}</ion-label>
            </ion-list-header>
            <ion-item v-for="course in courses" :key="course.code">
                <ion-label>{{ course.name }}</ion-label>
            </ion-item>
        </ion-list>
    </l-page>
</template>
