export enum WhereOperator {
  EQ = 'eq',
  NEQ = 'neq',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  IN = 'in',
  NIN = 'nin',
  LIKE = 'like',
}

type WherePrimitive = string | number | boolean;
export type WhereValue =
  | WherePrimitive
  | { [key in WhereOperator]?: WherePrimitive | Array<WherePrimitive> };

export function where(whereClause: Record<string, WhereValue>) {
  return JSON.stringify(whereClause); 
}