<script setup lang="ts">
import { IonCard, IonCardContent, IonImg } from '@ionic/vue';
import { defineProps } from 'vue';
import { TutorProfile } from '../../api/learner.api';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { computed } from 'vue';
import { t } from '@/plugins/i18n';

const props = defineProps<{
    tutor: TutorProfile;
    lesson: { start: string; end: string };
}>();

const format = useLocaleDateFormat();

const getLocaleTime = () =>
    computed(() => {
        const start = format(new Date(props.lesson.start), 'dd.MM.yyyy  kk:mm');
        const end = format(new Date(props.lesson.end), 'kk:mm');

        return `${start} - ${end}`;
    });

const getLanguages = () =>
    computed(() => {
        return props.tutor.languages?.length
            ? props.tutor.languages.join(', ')
            : t('learner.classDetails.noLanguages');
    });
</script>

<template>
    <ion-card class="class-details">
        <ion-card-content class="tutor-info">
            <ion-img :src="tutor.profilePhoto" alt="Tutor's profile picture" />
            <div class="tutor-details">
                <h2>{{ tutor.name }}</h2>
                <p class="tutor-language">{{ getLanguages() }}</p>
                <p class="tutor-language">{{ tutor.profileExperienceYears }}</p>
                <p class="tutor-rating"></p>
            </div>
        </ion-card-content>

        <ion-card-content class="lesson-info">
            <p>
                {{ t('learner.classDetails.dateAndTime') }} <strong>{{ getLocaleTime() }}</strong>
            </p>
        </ion-card-content>
    </ion-card>
</template>

<style scoped>
.class-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tutor-info {
    display: flex;
    align-items: center;
}

ion-img::part(image) {
    margin-right: 15px;
    width: 65px;
    height: 65px;
    border-radius: 5px;
}
</style>
