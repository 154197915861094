import { paths } from '../../../../../../packages/api-client/src';
import { client } from '@/core/api/client-proxy';

export type TutorProfile =
    paths['/tutor/profile']['get']['responses']['200']['content']['application/json'];
export type Tutoring =
    paths['/tutor/tutorings/']['get']['responses']['200']['content']['application/json']['data']['0'];

export class TutorApi {
    async getTutorProfile(): Promise<TutorProfile> {
        const { data } = await client.GET('/tutor/profile');
        return data!;
    }

    async getTutorings(): Promise<Tutoring[]> {
        const responses = await client.GET('/tutor/tutorings/');
        return responses.data?.data as Tutoring[];
    }
}

export const tutorApi = new TutorApi();
