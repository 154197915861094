import OnboardingLayout from '@/core/layouts/OnboardingLayout.vue';
import ChatPage from '@/modules/chat/pages/ChatPage.vue';
import ChatsPage from '@/modules/chat/pages/ChatsPage.vue';
import TutorLayout from '@/modules/tutor/layouts/TutorLayout.vue';
import TutorCalendarPage from '@/modules/tutor/pages/TutorCalendarPage.vue';
import TutorDeviceCheckPage from '@/modules/tutor/pages/TutorDeviceCheckPage.vue';
import TutorMaterialsPage from '@/modules/tutor/pages/TutorMaterialsPage.vue';
import TutorOnboardingHomePage from '@/modules/tutor/pages/TutorOnboardingHomePage.vue';
import TutorOnboardingPage from '@/modules/tutor/pages/TutorOnboardingPage.vue';
import HomePage from '@/pages/HomePage.vue';
import { RouteRecordRaw } from 'vue-router';

export const tutorRoutes: RouteRecordRaw[] = [
    {
        path: '/tutor',
        component: TutorLayout,
        children: [
            {
                path: '',
                redirect: '/tutor/home',
            },
            {
                path: 'home',
                name: 'TutorHome',
                component: TutorOnboardingHomePage,
            },
            {
                path: 'device-check',
                name: 'DeviceCheck',
                component: TutorDeviceCheckPage,
            },
            {
                path: 'my-learners',
                name: 'MyLearners',
                component: HomePage,
            },
            {
                path: 'calendar',
                name: 'TutorCalendar',
                component: TutorCalendarPage,
            },
            {
                path: 'materials',
                name: 'Materials',
                component: TutorMaterialsPage,
            },
            {
                path: 'chats',
                name: 'TutorChats',
                component: ChatsPage,
            },
        ],
    },
    {
        path: '/tutor/onboarding',
        component: OnboardingLayout,
        children: [
            {
                path: 'survey',
                name: 'TutorSurvey',
                component: TutorOnboardingPage,
            },
        ],
    },
    {
        path: '/tutor/chats/:chatUid',
        name: 'TutorChat',
        component: ChatPage,
    },
];
