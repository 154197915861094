import { client } from '@/core/api/client-proxy';
import { paths } from '@client';
import { where } from '@common/utils/where';

export type Course =
    paths['/tutor/courses/']['get']['responses']['200']['content']['application/json']['data']['0'];
export type Lesson =
    paths['/tutor/lessons/']['get']['responses']['200']['content']['application/json']['data']['0'];
export class CatalogApi {
    // TODO: get global locale to pass to the API
    async getCourses(): Promise<Course[]> {
        const res = await client.GET('/tutor/courses/', { params: { query: { lang: 'en' } } });
        return res.data!.data;
    }

    async getLessons(courseCode: string): Promise<Lesson[]> {
        const res = await client.GET('/tutor/lessons/', {
            params: {
                query: {
                    where: where({ 'course.code': courseCode }),
                    sortBy: 'code',
                    pageSize: 100,
                },
            },
        });
        return res.data!.data;
    }
}

export const catalogApi = new CatalogApi();
