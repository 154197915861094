<script setup lang="ts">
import { onMounted, ref, useTemplateRef, watch } from 'vue';
import { CustomChatMessage, useChatStore } from '../store/chat.store';
import { useRoute, useRouter } from 'vue-router';
import { IonTextarea, IonFooter, IonToolbar, IonContent, IonHeader, useKeyboard } from '@ionic/vue';
import {
    chevronBackOutline,
    attachOutline,
    checkmarkOutline,
    checkmarkDoneOutline,
    timeOutline,
    key,
} from 'ionicons/icons';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { sendMessageToShw } from '../utils/chat.utils';
import { t } from '@/plugins/i18n';
import { subDays } from 'date-fns';

let lastChangeTime = 0;
const messageText = ref('');

const route = useRoute();
const router = useRouter();

const chatStore = useChatStore();

const authStore = useAuthStore();

const messageRefs = useTemplateRef('messages');

const inputRef = useTemplateRef('input');

const baseToolbarHeight = '64px';
const headerHeight = ref(baseToolbarHeight);
const footerHeight = ref(baseToolbarHeight);
const baseInputHeight = ref('30px');
const inputMaxHeight = ref('65px');

const observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
            const messageUid = entry.target.getAttribute('uid') as string;
            if (
                chatStore.activeChat &&
                messageUid > (chatStore.activeChat.latestReadMessageUid as string)
            ) {
                chatStore.setActiveChatMessageRead(messageUid);
                observer.unobserve(entry.target);
            }

            if (
                chatStore.activeChat?.chatMessagesMeta?.hasMore &&
                messageUid === chatStore.activeChat.chatMessagesMeta.nextCursor
            ) {
                await chatStore.loadActiveChatMessages(messageUid);
                observeNeededMessages();
            }
        }
    });
});

const sendMessage = () => {
    const text = messageText.value;

    if (!text) {
        return;
    }

    const clientMessageId = Date.now().toString();

    chatStore.addMessageToActiveChat({
        chatUid: chatStore.activeChat?.uid as string,
        content: text,
        senderUid: authStore.user.uid,
        uid: clientMessageId,
        isMessageDelivered: true,
        isMessageReceivedByServer: true,
        createdAt: new Date().toISOString(), // TODO
        deletedAt: new Date().toISOString(), // TODO
        editedAt: new Date().toISOString(), // TODO
    });

    const dto = {
        type: 'send_message',
        chatUid: chatStore.activeChat?.uid,
        content: text,
        clientMessageId,
    };

    sendMessageToShw(dto);

    messageText.value = '';
};

const getMessageClassBySender = (message: CustomChatMessage) => {
    if (message.senderUid === authStore.user.uid) {
        return 'message-right';
    } else {
        return 'message-left';
    }
};

const getCheckmarkIconByMessage = (message: CustomChatMessage) => {
    if (
        message.uid <= (chatStore.activeChat?.latestReadMessageUid as string) ||
        message.isMessageDelivered
    ) {
        return checkmarkDoneOutline;
    } else if (message.isMessageReceivedByServer) {
        return checkmarkOutline;
    } else {
        return timeOutline;
    }
};

const getMessageSendedTime = (date: Date) => {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();

    const nowDate = new Date();
    const curD = nowDate.getDate();
    const curM = nowDate.getMonth();
    const curY = nowDate.getFullYear();

    if (d === curD && m === curM && y === curY) {
        return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
    }
    if (d === subDays(nowDate, 1).getDate() && m === curM && y === curY) {
        return `${t('chat.yesterday')} ${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })}`;
    }
    if (y === curY) {
        return date.toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
    }
    return date.toLocaleDateString();
};

const observeNeededMessages = () => {
    messageRefs.value?.forEach((messageRef) => {
        if (messageRef.classList.contains('unread-message')) {
            observer.observe(messageRef);
        }
    });

    if (chatStore.activeChat?.chatMessagesMeta?.hasMore) {
        const lastMessageUid = chatStore.activeChat.chatMessagesMeta.nextCursor;

        const msgRef = messageRefs.value?.find(
            (msgRef) => msgRef.getAttribute('uid') === lastMessageUid,
        );

        if (msgRef) {
            observer.observe(msgRef);
        }
    }
};

const observeInputSize = async () => {
    const textarea = await inputRef.value?.$el.getInputElement();
    textarea.style.maxHeight = inputMaxHeight.value;

    const observer = new ResizeObserver((val) => {
        const height = val[0].contentRect.height;

        let textareaHeight = height;

        if (height > parseInt(inputMaxHeight.value)) {
            textareaHeight = parseInt(inputMaxHeight.value);
        }

        footerHeight.value = `${parseInt(baseToolbarHeight) + (textareaHeight - parseInt(baseInputHeight.value))}px`;
    });

    observer.observe(textarea);
};

const formatMessage = (message: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Замена URL на гиперссылки
    return message.replace(urlRegex, '<a href="$1" class="underline" target="_blank">$1</a>');
};

async function fetchData() {
    await chatStore.setActiveChat(route.params.chatUid as string);

    observeNeededMessages();
    observeInputSize();
}

watch(
    () => chatStore.activeChatMessages,
    (newMessages) => {
        const newMessage = newMessages[0];

        if (newMessage) {
            if (newMessage.senderUid !== authStore.user.uid) {
                const msgRef = messageRefs.value?.find(
                    (r) => r.getAttribute('uid') === newMessage.uid,
                );

                if (msgRef) {
                    observer.observe(msgRef);
                }
            }
        }
    },
    { deep: true, flush: 'post' },
);

const checkInputScroll = async () => {
    const textarea = await inputRef.value?.$el.getInputElement();

    if (textarea.scrollHeight > parseInt(inputMaxHeight.value)) {
        textarea.style.overflowY = 'scroll';
    } else {
        textarea.style.overflowY = 'hidden';
    }

    textarea.scrollTop = textarea.scrollHeight;
};

const inputHandler = async (value: CustomEvent) => {
    messageText.value = value.detail.value;

    if (Date.now() >= lastChangeTime + 3000) {
        const dto = {
            type: 'typing',
            chatUid: chatStore.activeChat?.uid,
        };
        sendMessageToShw(dto);

        lastChangeTime = Date.now();
    }
};

const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
        if (event.ctrlKey) {
            messageText.value += '\n';
            event.preventDefault();
        } else {
            sendMessage();
            event.preventDefault();
        }
    }
};

watch(messageText, () => {
    checkInputScroll();
});

onMounted(fetchData);
</script>

<template>
    <ion-header>
        <ion-toolbar>
            <ion-icon
                class="icon-back"
                slot="start"
                :icon="chevronBackOutline"
                @click="router.go(-1)"
            ></ion-icon>
            <ion-avatar slot="start" class="chat-avatar">
                <img
                    :src="chatStore.activeChat?.chatAvatar"
                    onerror="this.src = '/icons/no-avatar.png'"
                />
            </ion-avatar>
            <div class="chat-name">
                <div>{{ chatStore.activeChat?.chatName }}</div>
                <ion-note
                    class="typing-indicator-container"
                    v-if="chatStore.activeChat?.isParticipantTyping"
                >
                    <span>Печатает</span>
                    <div class="typing-animation">
                        <span class="dot">.</span>
                        <span class="dot">.</span>
                        <span class="dot">.</span>
                    </div>
                </ion-note>
            </div>
        </ion-toolbar>
    </ion-header>
    <ion-content class="content-container" ref="content">
        <div class="message-container">
            <div
                :class="[
                    'message',
                    getMessageClassBySender(message),
                    {
                        'unread-message':
                            message.uid > (chatStore.activeChat?.latestReadMessageUid as string) &&
                            message.senderUid !== authStore.user.uid,
                    },
                ]"
                v-for="message in chatStore.activeChatMessages.slice()"
                :key="message.uid"
                :uid="message.uid"
                ref="messages"
            >
                <div class="message-content" v-html="formatMessage(message.content)" />
                <br />
                <div class="message-date">
                    <ion-note class="caption-6">{{
                        getMessageSendedTime(new Date(message.createdAt as string))
                    }}</ion-note>
                    <ion-icon
                        :class="[
                            'checkmark',
                            {
                                'message-read':
                                    message.uid <=
                                    (chatStore.activeChat?.otherParticipant
                                        .latestReadMessageUid as string),
                            },
                        ]"
                        :icon="getCheckmarkIconByMessage(message)"
                    ></ion-icon>
                </div>
            </div>
        </div>
    </ion-content>
    <ion-footer>
        <ion-toolbar class="bottom-panel">
            <ion-icon
                class="attach-icon"
                slot="start"
                :icon="attachOutline"
                size="large"
            ></ion-icon>
            <div class="input-container">
                <ion-textarea
                    v-model="messageText"
                    @ion-input="inputHandler"
                    ref="input"
                    :rows="1"
                    class="input"
                    :auto-grow="true"
                    fill="outline"
                    @keydown="keyDownHandler"
                />
            </div>
            <ion-img
                class="send-button"
                slot="end"
                src="/icons/send-button.svg"
                alt="send"
                @click="sendMessage"
            />
        </ion-toolbar>
    </ion-footer>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

$vh: 100svh;

ion-header ion-toolbar {
    height: v-bind(headerHeight);
}

ion-footer ion-toolbar {
    height: v-bind(footerHeight);
}

ion-toolbar {
    --background: $grey-100;
    display: flex;
}

.icon-back {
    margin-left: 15px;
}

.container {
    overflow-y: hidden;
}

.content-container {
    height: calc($vh - v-bind(headerHeight) - v-bind(footerHeight));
}

.chat-avatar {
    width: 40px;
    height: 40px;
    margin-left: 20px;
}

.chat-name {
    margin-left: 10px;
}

.bottom-panel {
    display: flex;
}

.attach-icon {
    transform: rotate(45deg);
    margin-left: 15px;
}

.input-container {
    padding: 0 20px;

    .input {
        min-height: v-bind(baseInputHeight);
        max-height: v-bind(inputMaxHeight);
        border: 1px solid $grey-400;
        border-radius: $default-border-radius;
        width: 100%;
        resize: none;
        --border-width: 0;
        --padding-start: 10px;
        --padding-top: 5px;
        --padding-bottom: 0;
        --highlight-color-focused: $grey-100;

        textarea {
            max-height: v-bind(inputMaxHeight);
        }

        &:focus {
            border: none;
        }
    }
}

.native-wrapper .native-textarea {
    background-color: black !important;
    padding: 0;
    min-height: 30px !important;
    height: 30px;
}

.send-button {
    width: 48px;
    height: 48px;
    margin-right: 15px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.message-container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    height: 100%;
}

.message {
    max-width: 80%;
    margin: 10px 25px;
    padding: 15px;
    border-radius: $default-border-radius;

    .message-content {
        display: inline-block;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
    }
}

.message-left {
    align-self: flex-start;
    background-color: $grey-300;
    border-bottom-left-radius: 0;

    .message-date {
        text-align: left;

        .checkmark {
            display: none;
        }
    }
}

.message-right {
    align-self: flex-end;
    background-color: $blue-light;
    border-bottom-right-radius: 0;

    .message-date {
        text-align: right;

        .checkmark {
            margin-left: 7px;
            width: 14px;
            height: 14px;
        }

        .message-read {
            color: $purple-primary;
        }
    }
}

.message-date {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
}

ion-footer,
ion-header {
    z-index: 999 !important;
}

// typing animation

.typing-indicator-container {
    display: flex;
}

.typing-animation {
    display: flex;
    align-items: center;
}

.dot {
    margin: 0 2px;
    opacity: 0;
    animation: blink 1.4s infinite both;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%,
    20%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
</style>
