import Cookies from 'js-cookie';
import { useTutorStore } from '@/modules/tutor/store/tutor.store';

export const tutorOnboardingGuard = (to) => {
    const role = Cookies.get('role');
    const tutorStore = useTutorStore();
    if (role === 'tutor') {
        if (tutorStore.isOnboarding) {
            if (to.path !== '/tutor/onboarding/survey') {
                return '/tutor/onboarding/survey';
            }
        }
    }

    return true;
};
