import { createI18n } from 'vue-i18n';
import { pluralizationRules } from './pluralization-rules';
import en from './locales/en.json';
import ru from './locales/ru.json';
import Cookies from 'js-cookie';

let savedLanguage;
if (typeof window !== 'undefined') {
    savedLanguage = Cookies.get('language') || 'en';
}

export const i18n = createI18n({
    legacy: false,
    locale: savedLanguage,
    fallbackLocale: 'en',
    messages: {
        en,
        ru,
    },
    pluralizationRules,
});

export const { t } = i18n.global;
