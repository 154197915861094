<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { IonCard, IonCardContent } from '@ionic/vue';
import { useTutorCatalogStore } from '../../store/tutors-catalog.store';
import LPreviewSchedule from './LPreviewSchedule.vue';
import { t } from '@/plugins/i18n';
import { Ref, ref, watch } from 'vue';
import { useLearnerApi } from '../../api/learner.api';
import { useRouter } from 'vue-router';

const props = defineProps({
    activeCardPosition: Number,
});
const { activeTutor, tutors } = storeToRefs(useTutorCatalogStore());

const learnerApi = useLearnerApi();
const router = useRouter();

const isScheduleLoading = ref(false);
const occupiedSlots = ref([]) as Ref<any[]>;

const today = new Date();
const startDate = today.toISOString();
const endDateUnFormatted = new Date(today);
endDateUnFormatted.setDate(today.getDate() + 6);
const endDate = endDateUnFormatted.toISOString();

const getOccupiedSlots = async () => {
    const response = await learnerApi.getTutorAvailableSlots({
        startTime: startDate,
        endTime: endDate,
        tutorUserUid: tutors.value![activeTutor.value!].userUid,
    });

    occupiedSlots.value = response.data!.map((slot) => {
        const date = slot.startTime.split('T')[0];
        const startTime = new Date(slot.startTime as string).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        });
        const endTime = new Date(slot.endTime as string).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        });
        return {
            date: date,
            time: `${startTime} - ${endTime}`,
        };
    });
};

watch(activeTutor, async () => {
    isScheduleLoading.value = true;

    await getOccupiedSlots();

    isScheduleLoading.value = false;
});

const viewFullSchedule = () => {
    router.push(`/learner/tutor/${tutors.value![activeTutor.value!].userUid}`);
};
</script>

<template>
    <transition name="move-transition">
        <div
            v-show="activeTutor !== null"
            class="right-card"
            :style="`top: ${props.activeCardPosition}px ; `"
        >
            <ion-card>
                <ion-card-content>
                    <div class="video-container">
                        <iframe
                            :src="tutors![activeTutor!]?.profileVideo"
                            width="100%"
                            style="border: none; border-radius: 24px"
                        >
                        </iframe>
                    </div>
                    <div class="schedule-container">
                        <l-preview-schedule
                            :startDate="startDate"
                            :endDate="endDate"
                            :timeSlotSize="4"
                            :occupiedSlots="occupiedSlots"
                            :isLoading="isScheduleLoading"
                        />
                    </div>

                    <div class="view-full-schedule">
                        <p @click="viewFullSchedule">
                            {{ t('learner.tutorCatalog.tutorCard.toFullSchedule') }} →
                        </p>
                    </div>
                </ion-card-content>
            </ion-card>
        </div>
    </transition>
</template>

<style scoped>
/* Стили для правой карточки */
.right-card {
    position: relative;
    transition: all 0.5s ease;
    width: 300px;
}

.video-container video {
    width: 100%;
    height: auto;
}

.schedule-container {
    margin: 16px 0;
}

.view-full-schedule {
    text-align: center;
    margin-top: 16px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
</style>
