import { initializeApp } from 'firebase/app';
import { getMessaging, Messaging } from 'firebase/messaging';
import { App } from 'vue';

const fbConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG as string);
const fbApp = initializeApp(fbConfig);

let fbMessaging: Messaging;

if (navigator.serviceWorker) {
    fbMessaging = getMessaging(fbApp);
}

export { fbApp, fbMessaging };

export const setupFirebase = (app: App) => {
    // do nothing
};
