/* eslint-disable no-param-reassign */
import { Ref } from 'vue';
import { SurveyModel, ServerValidateQuestionsEvent } from 'survey-core';
import { useFormApi } from '../api/form.api';
import { ILatestResponse } from '../api/form.dto';

export function useFormSubmission(
    role: 'tutor' | 'learner',
    latestResponse: Ref<ILatestResponse | null | undefined>,
    code: string,
    formCompleted: Ref<boolean>,
) {
    const formApi = useFormApi(role);

    const onSubmit = async (sender: SurveyModel, callback?: Function) => {
        try {
            if (latestResponse.value?.uid && latestResponse.value?.status === 'draft') {
                await formApi.submitForm({
                    code,
                    uid: latestResponse.value.uid,
                    content: JSON.stringify(sender.data),
                });
            }
            formCompleted.value = true;

            if (callback) {
                callback();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const onNextClick = async (sender: SurveyModel, options: ServerValidateQuestionsEvent) => {
        const { data, currentPageNo } = sender;
        try {
            if (!latestResponse.value?.uid) {
                const savedStep = await formApi.saveFormStep({
                    code,
                    content: JSON.stringify({ ...data, page: currentPageNo + 1 }),
                });
                if (!savedStep.success) throw new Error('Error saving form step');
                latestResponse.value = {
                    uid: savedStep.data!.uid,
                    status: savedStep.data!.status,
                };
            } else {
                await formApi.updateFormStep({
                    code,
                    uid: latestResponse.value?.uid,
                    content: JSON.stringify({ ...data, page: currentPageNo + 1 }),
                });
            }
            options.complete();
        } catch (error) {
            console.error('Error saving form step:', error);
        }
    };

    return { onSubmit, onNextClick };
}
