/* eslint-disable no-underscore-dangle */
import { paths } from '../../../../../packages/api-client/src';
import { alertController } from '@ionic/vue';
import createClient, { Client } from 'openapi-fetch';
import { MediaType } from 'openapi-typescript-helpers';
import { t } from '@/plugins/i18n';
import { API_URL } from '../config/config';

export interface ClientProxySettings {
    errorNotify: boolean;
    errorThrow: boolean;
}

export function createClientProxy<Paths extends object, Media extends MediaType = MediaType>(
    proxySettings?: ClientProxySettings,
): Pick<Client<Paths, Media>, 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'> {
    const _client = createClient<Paths, Media>({ baseUrl: API_URL, credentials: 'include' });
    return {
        GET: (path: unknown, options: unknown) =>
            proxyMethod(_client, 'GET', path, options, proxySettings),
        PUT: (path: unknown, options: unknown) =>
            proxyMethod(_client, 'PUT', path, options, proxySettings),
        POST: (path: unknown, options: unknown) =>
            proxyMethod(_client, 'POST', path, options, proxySettings),
        DELETE: (path: unknown, options: unknown) =>
            proxyMethod(_client, 'DELETE', path, options, proxySettings),
        PATCH: (path: unknown, options: unknown) =>
            proxyMethod(_client, 'PATCH', path, options, proxySettings),
        // biome-ignore lint/suspicious/noExplicitAny: apply types only on client proxy due to openapi-fetch internal complexity
    } as any;
}

export const client = createClientProxy<paths>({ errorNotify: true, errorThrow: true });

/*
 * Proxy method to API calls
 * Here we can centralize error handling, caching, etc.
 */
async function proxyMethod(
    // biome-ignore lint/suspicious/noExplicitAny: skip types on internal openapi-fetch client
    // eslint-disable-next-line no-shadow
    client: any,
    method: string,
    path: unknown,
    options: unknown,
    proxySettings?: ClientProxySettings,
) {
    const res = client[method](path, options);
    if (res.error) {
        if (proxySettings?.errorNotify) {
            const alert = await alertController.create({
                header: t('error_header'),
                message: res.error.message,
                buttons: ['OK'],
            });
            await alert.present();
        }
        if (proxySettings?.errorThrow) {
            throw res.error;
        }
    }
    return res;
}
