<script setup lang="ts">
import { onMounted } from 'vue';
import { useChatStore } from '../store/chat.store';
import {
    IonList,
    IonItem,
    IonNote,
    IonText,
    IonAvatar,
    IonListHeader,
    IonLabel,
    useIonRouter,
    createAnimation,
} from '@ionic/vue';
import { requestNotificationPermission } from '../utils/chat.utils';
import { subDays } from 'date-fns';
import { t } from '@/plugins/i18n';

const ionRouter = useIonRouter();

const chatStore = useChatStore();

onMounted(async () => {
    await requestNotificationPermission();
});

const getChatLastActivityTime = (date: Date) => {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();

    const curD = date.getDate();
    const curM = date.getMonth();
    const curY = date.getFullYear();

    if (d === curD && m === curM && y === curY) {
        return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
    }
    if (d === subDays(date, 1).getDate() && m === curM && y === curY) {
        return t('chat.yesterday');
    }
    if (y === curY) {
        return date.toLocaleDateString(undefined, { day: 'numeric', month: 'numeric' });
    }
    return date.toLocaleDateString();
};

const animation = createAnimation();

const toChat = (chatUid: string) => ionRouter.push(`chats/${chatUid}`, () => animation);
</script>

<template>
    <ion-list lines="none">
        <ion-list-header>
            <ion-label>{{ t('chat.chats') }}</ion-label>
        </ion-list-header>
        <div v-for="chat in chatStore.chats">
            <ion-item class="chat" color="white" aria-hidden="true" @click="toChat(chat.uid)">
                <div class="chat-body">
                    <ion-avatar slot="start" class="chat-avatar">
                        <img :src="chat.chatAvatar" onerror="this.src = '/icons/no-avatar.png'" />
                    </ion-avatar>
                    <div class="chat-content">
                        <div>
                            <strong>{{ chat.chatName }}</strong>
                        </div>
                        <div>
                            <ion-text class="last-message-text">{{
                                chat.latestMessageContent
                            }}</ion-text>
                        </div>
                    </div>
                </div>
                <div slot="end" style="text-align: end">
                    <ion-note class="last-message-time">{{
                        getChatLastActivityTime(new Date(chat.createdAt as string))
                    }}</ion-note>
                    <br />
                    <ion-note class="unread-count" v-if="chat.unreadCount">{{
                        chat.unreadCount
                    }}</ion-note>
                </div>
            </ion-item>
            <div class="line"></div>
        </div>
    </ion-list>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.unread-count {
    background-color: $purple-primary;
    border-radius: 30px;
    color: $grey-100;
    padding: 1px 5px;
    font-size: 14px;
}

.line {
    width: 100%;
    background-color: #e2e1e5;
    height: 1px;
}

.chat {
    /* height: 80px;
  dis */
    margin: 6px;

    &::part(native) {
        background-color: $grey-100;
    }
}

.chat-body {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.chat-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-left: 10px;
    height: 100%;
    width: 100%;
}

.chat-avatar {
    min-height: 64px;
    min-width: 64px;
}

.last-message-time {
    font-size: 14px;
}

.last-message-text {
    display: block;
    width: calc(100% - 70px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
