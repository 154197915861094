import LoginPage from '@/modules/auth/pages/LoginPage.vue';
import ResetPasswordPage from '@/modules/auth/pages/ResetPasswordPage.vue';
import SelectYourRolePage from '@/modules/auth/pages/SelectYourRolePage.vue';
import SignUpPage from '@/modules/auth/pages/SignUpPage.vue';
import HomePage from '@/pages/HomePage.vue';
import { RouteRecordRaw } from 'vue-router';
import { selectRoleGuard } from './guards/select-role.guard';
import ChatPage from '@/modules/chat/pages/ChatPage.vue';

export const commonRoutes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        beforeEnter: selectRoleGuard,
    },
    // Auth routes
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUpPage,
    },
    {
        path: '/select-role',
        name: 'SelectRole',
        component: SelectYourRolePage,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPasswordPage,
    },
];
