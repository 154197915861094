import { loadingController } from '@ionic/vue';
import Cookies from 'js-cookie';
import type { RouteLocationNormalized } from 'vue-router';
import { useTutorStore } from '@/modules/tutor/store/tutor.store';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { useLearnerStore } from '@/modules/learner/store/learner.store';

export const AUTH_ROUTES = ['/login', '/signup', '/reset-password'];

export const authGuard = async (to: RouteLocationNormalized) => {
    const authStore = useAuthStore();

    if (authStore.isAuthenticated) {
        return true;
    }

    // check if we have a role in the cookies, means we were authenticated before, fetch profile and set authenticated status
    const role = Cookies.get('role');
    if (role) {
        const loading = await loadingController.create({
            message: 'Loading...',
        });
        loading.present();

        switch (role) {
            case 'learner':
                await useLearnerStore().fetch();
                break;
            case 'tutor':
                await useTutorStore().fetch();
                break;
            case 'backoffice':
                // TODO
                break;
            case 'new':
                break;
            default:
                throw new Error(`Unknown role ${role}`);
        }

        loading.dismiss();

        if (authStore.isAuthenticated) {
            return true;
        }
    }

    if (AUTH_ROUTES.includes(to.path)) {
        return true;
    }

    return { path: '/login' };
};
