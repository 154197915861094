<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import { IonItem, IonLabel, IonList } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
    <l-page>
        <ion-list>
            <ion-item href="tutor/device-check">
                <ion-label>{{ t('tutor.home.deviceCheck') }}</ion-label>
            </ion-item>
            <ion-item>
                <ion-label>{{ t('tutor.home.personCheck') }}</ion-label>
            </ion-item>
            <ion-item>
                <ion-label>{{ t('tutor.home.addCard') }}</ion-label>
            </ion-item>
            <ion-item>
                <ion-label>{{ t('tutor.home.selectSchedule') }}</ion-label>
            </ion-item>
        </ion-list>
    </l-page>
</template>
