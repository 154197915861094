import { useAuthStore } from '@/modules/auth/store/auth.store';
import { defineStore } from 'pinia';
import { tutorApi, TutorProfile } from '../api/tutor.api';

export const useTutorStore = defineStore('tutor', {
    state: () => ({
        profile: {} as TutorProfile,
    }),
    getters: {
        isOnboarding: (state) => state.profile.tutor?.status === null,
    },
    actions: {
        async fetch() {
            const tutorProfile = await tutorApi.getTutorProfile();
            this.profile = tutorProfile;
            useAuthStore().setAuthUser(tutorProfile.user);
        },
    },
});
