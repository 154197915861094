<script setup lang="ts">
import { onMounted } from 'vue';
import LLessonCard from './LLessonCard.vue';
import { useCalendarStore } from '../../store/calendar.store';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useRoute } from 'vue-router';

const lessonStore = useCalendarStore();
const route = useRoute();
const { lessons, loading } = storeToRefs(useCalendarStore());

const fetchData = async () => {
    const startTime = new Date();
    const endTime = new Date();
    endTime.setDate(startTime.getDate() + 30);

    await lessonStore.fetchLessons({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        statuses: ['scheduled'],
    });
};

onMounted(async () => {
    await fetchData();
});

watch(route, async () => {
    if (route.path.includes('calendar')) {
        await fetchData();
    }
});
</script>

<template>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else>
        <l-lesson-card v-for="lesson in lessons" :key="lesson.uid" :lesson="lesson" />
    </div>
</template>

<style scoped>
.loading {
    text-align: center;
    font-size: 1.2rem;
    padding: 20px;
}
</style>
