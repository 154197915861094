<script setup lang="ts">
import { computed } from 'vue';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';

const format = useLocaleDateFormat();
const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    timeSlotSize: {
        type: Number,
        default: 4,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    occupiedSlots: {
        type: Array<{ date: string; time: string }>,
        default: () => [],
    },
});

// Calculating an array of dates between startDate and endDate
const dates = computed(() => {
    const start = new Date(props.startDate);
    const end = new Date(props.endDate);
    const datesArray = [] as Date[];

    while (start <= end) {
        datesArray.push(new Date(start));
        start.setDate(start.getDate() + 1);
    }

    return datesArray;
});

// Calculating Time Slots
const timeSlots = computed(() => {
    const slots = [] as string[];
    for (let i = 0; i < 24; i += props.timeSlotSize) {
        const start = `${i.toString().padStart(2, '0')}`;
        const end = `${(i + props.timeSlotSize).toString().padStart(2, '0')}`;
        slots.push(`${start} - ${end}`);
    }
    return slots;
});

const formatDate = (date: Date) => format(date, 'eeeeee dd.MM');

const isSlotOccupied = (date: Date, timeSlot: string) => {
    const [slotStart, slotEnd] = timeSlot
        .split(' - ')
        .map((time) => new Date(`${format(date, 'yyyy-MM-dd')}T${time}:00`));

    return props.occupiedSlots.some((slot) => {
        const slotDate = new Date(slot.date);
        const [occupiedStart, occupiedEnd] = slot.time
            .split(' - ')
            .map((time) => new Date(`${slot.date}T${time}:00`));

        return (
            slotDate.toDateString() === date.toDateString() &&
            ((slotStart >= occupiedStart && slotStart < occupiedEnd) ||
                (slotEnd > occupiedStart && slotEnd <= occupiedEnd) ||
                (slotStart <= occupiedStart && slotEnd >= occupiedEnd))
        );
    });
};
</script>

<template>
    <div class="calendar-container" :class="{ isLoading }">
        <div class="header">
            <div class="header-cell"></div>
            <div v-for="(date, index) in dates" :key="index" class="header-cell">
                {{ formatDate(date) }}
            </div>
        </div>
        <div class="rows">
            <div v-for="(time, index) in timeSlots" :key="index" class="row">
                <div class="time-cell">{{ time }}</div>
                <div
                    v-for="(date, dateIndex) in dates"
                    :key="dateIndex"
                    class="slot-cell"
                    :class="{ occupied: isSlotOccupied(date, time) }"
                ></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.calendar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.isLoading {
    opacity: 0.5;
    pointer-events: none;
}

.header {
    display: flex;
    flex: 0 0 auto;
    border-bottom: 1px solid #ddd;
}

.header-cell {
    flex: 1;
    text-align: center;
    padding: 4px 2px;
    font-weight: bold;
    font-size: 0.6rem;
    white-space: normal;
}

.rows {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
}

.row {
    display: flex;
    flex-grow: 1;
}

.time-cell {
    width: 38px;
    text-align: center;
    padding: 5px 2px;
    font-size: 0.6rem;
}

.slot-cell {
    flex: 1;
    border: 1px solid #ddd;
    transition: background-color 0.2s;
}

.slot-cell.occupied {
    background-color: var(--ion-color-danger);
}
</style>
