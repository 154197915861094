<script setup lang="ts">
import LButton from '@/core/components/LButton.vue';
import { t } from '@/plugins/i18n';
import { IonCard, IonCardContent } from '@ionic/vue';

const emit = defineEmits(['verification-success']);
</script>

<template>
    <ion-card>
        <ion-card-content>
            <div class="ion-padding">
                {{ t('learner.classDetails.submitClassTitle') }}
            </div>
            <div>
                <l-button @click="emit('verification-success')">
                    {{ t('learner.classDetails.submitClassButton') }}
                </l-button>
            </div>
        </ion-card-content>
    </ion-card>
</template>

<style scoped>
.verification-form {
    padding: 16px;
    text-align: center;
}

.input-container {
    max-width: 300px;
    margin: 0px auto;
    padding: 16px;
}
</style>
