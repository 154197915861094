<script setup lang="ts">
import LessonConfirmationLayout from '../layouts/LessonConfirmationLayout.vue';
import LClassDetails from '../components/booking/LClassDetails.vue';
import { useRoute, useRouter } from 'vue-router';
import LPage from '@/core/components/LPage.vue';
import LVerificationForm from '@/modules/auth/components/verification/LVerificationForm.vue';
import { ref } from 'vue';
import { TutorProfile, useLearnerApi } from '../api/learner.api';
import { onMounted } from 'vue';
import { Ref } from 'vue';
import { useLearnerStore } from '../store/learner.store';
import { storeToRefs } from 'pinia';
import LConfirmationTrial from '../components/booking/LConfirmationTrial.vue';
import { t } from '@/plugins/i18n';
import LButton from '@/core/components/LButton.vue';

const router = useRouter();
const route = useRoute();
const lernerApi = useLearnerApi();
const learnerStore = useLearnerStore();

const lessonData = ref(null) as Ref<{ start: string; end: string } | null>;
const lessonDateTime = ref(null) as Ref<{ start: Date; end: Date } | null>;
const tutorData = ref(null) as Ref<TutorProfile | null>;

const onVerificationSuccess = async () => {
    if (tutorData.value !== null && lessonDateTime.value !== null) {
        const { userUid } = tutorData.value;
        const { start, end } = lessonDateTime.value;
        const { success } = await lernerApi.scheduleTrialLesson({
            tutorUserUid: userUid,
            endTime: end.toISOString(),
            startTime: start.toISOString(),
            // todo
            subjectCode: 'english',
        });
        if (success) {
            await learnerStore.fetch();
            router.push('/learner/catalog');
        }
    }
};

const fetchData = async () => {
    const uid = route.query.uid as string;

    const start = route.query.start as string;
    const end = route.query.end as string;

    if (uid && start && end) {
        const lessonTime = { start: '', end: '' };
        lessonDateTime.value = { start: new Date(start), end: new Date(end) };
        lessonTime.start = new Date(start).toLocaleString('en-US', { timeZoneName: 'short' });
        lessonTime.end = new Date(end).toLocaleString('en-US', { timeZoneName: 'short' });

        lessonData.value = lessonTime;
        tutorData.value = await lernerApi.getTutor(uid);
    }
};

onMounted(() => {
    fetchData();
});
</script>

<template>
    <l-page>
        <l-button @click="router.back()">{{ t(`learner.verification.backButton`) }}</l-button>
        <lesson-confirmation-layout>
            <!-- Inject Class Details component -->
            <template #class-details>
                <l-class-details
                    v-if="tutorData && lessonData"
                    :lesson="lessonData"
                    :tutor="tutorData"
                />
            </template>

            <!-- Inject Verification component -->
            <template #verification>
                <l-confirmation-trial
                    v-if="learnerStore.profile.user.emailVerified"
                    @verification-success="onVerificationSuccess"
                />
                <l-verification-form v-else @verification-success="onVerificationSuccess" />
            </template>

            <!-- Inject Comments component (if needed) -->
            <!--
    <template #comments>
      <LComments :reviews="reviews" />
    </template>
    -->
        </lesson-confirmation-layout>
    </l-page>
</template>
