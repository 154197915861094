import Cookies from 'js-cookie';
import { useLearnerStore } from '@/modules/learner/store/learner.store';

export const learnerOnboardingGuard = (to) => {
    const role = Cookies.get('role');
    const learnerStore = useLearnerStore();

    if (role === 'learner') {
        if (learnerStore.isOnboardingForm) {
            if (to.path !== '/learner/onboarding') {
                return '/learner/onboarding';
            }
        } else if (learnerStore.isScheduleTrial) {
            // todo is it ok?
            if (!to.path.includes('trial-')) {
                return '/trial-catalog';
            }
        } else {
            if (to.path === '/trial-catalog') {
                return '/learner';
            }
            if (to.path === '/learner/onboarding') {
                return '/learner';
            }
        }
    }

    return true;
};
