<script setup lang="ts">
import { ref, onMounted, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonModal,
} from '@ionic/vue';
import { TutorProfile, useLearnerApi } from '../api/learner.api';
import { getYearWord } from '@/core/utils/get-year-word';
import LPage from '@/core/components/LPage.vue';
import { t } from '@/plugins/i18n';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LBookingCalendar from '@/modules/learner/components/booking/LBookingCalendar.vue';
import { useLearnerStore } from '../store/learner.store';
import { computed } from 'vue';
import LButton from '@/core/components/LButton.vue';

const learnerApi = useLearnerApi();
const localePref = 'learner.tutorCatalog.tutorProfile';
const { isSmallSize } = useScreenSize();
const learnerStore = useLearnerStore();
const isOpenBookingModal = ref(false);
const route = useRoute();
const router = useRouter();
const tutorData = ref({
    userUid: '',
    name: '',
    profileTitle: '',
    profileDescription: '',
    profileExperienceYears: 0,
    priceFromUsd: 0,
    profilePhoto: '',
    profileVideo: '',
    languages: [],
}) as Ref<TutorProfile>;

const isTrial = computed(() => learnerStore.isScheduleTrial);

const fetchTutorData = async () => {
    const { userUid } = route.params;
    const data = await learnerApi.getTutor(userUid as string);
    tutorData.value = data;
};

const getLanguages = () =>
    tutorData.value?.languages?.length
        ? tutorData.value.languages.join(', ')
        : 'No languages listed';

onMounted(() => {
    fetchTutorData();
});

const openModal = () => {
    isOpenBookingModal.value = true;
};

const closeModal = () => {
    isOpenBookingModal.value = false;
};
</script>

<template>
    <l-page>
        <l-button @click="() => router.push('/learner/catalog')">{{
            t(`${localePref}.backButton`)
        }}</l-button>
        <ion-modal
            :isOpen="isOpenBookingModal"
            :breakpoints="[0.5, 0.8, 1]"
            :initial-break="isSmallSize ? 1 : 0.8"
            @did-dismiss="closeModal"
        >
            <l-booking-calendar @closeModal="closeModal" :tutor-user-uid="tutorData.userUid" />
        </ion-modal>
        <ion-grid>
            <ion-row>
                <ion-col size="12" size-md="6">
                    <ion-card>
                        <div class="profile-header">
                            <ion-img :src="tutorData.profilePhoto" alt="Profile Photo" />
                            <div class="profile-details">
                                <ion-card-title>{{ tutorData.name }}</ion-card-title>
                                <ion-card-subtitle>{{ tutorData.profileTitle }}</ion-card-subtitle>
                                <p>{{ tutorData.profileDescription }}</p>
                                <p>
                                    <strong>{{ t(`${localePref}.experience`) }}</strong>
                                    {{ getYearWord(tutorData.profileExperienceYears!) }}
                                </p>
                                <p>
                                    <strong>{{ t(`${localePref}.price`) }}</strong>
                                    {{ tutorData.priceFromUsd }}
                                    {{ t(`${localePref}.lessonPrice`) }}
                                </p>
                                <p>
                                    <strong>{{ t(`${localePref}.languages`) }}</strong>
                                    {{ getLanguages() }}
                                </p>
                            </div>
                        </div>
                    </ion-card>
                </ion-col>

                <ion-col size="12" size-md="6">
                    <ion-card>
                        <iframe
                            :src="tutorData.profileVideo"
                            width="100%"
                            height="250px"
                            allowfullscreen
                        ></iframe>
                        <ion-card-content>
                            <l-button
                                expand="block"
                                :disabled="!isTrial"
                                @click="openModal"
                                color="primary"
                                >{{ t(`${localePref}.bookTrialLesson`) }}
                            </l-button>
                            <l-button expand="block" fill="outline">{{
                                t(`${localePref}.sendMessage`)
                            }}</l-button>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
        </ion-grid>
    </l-page>
</template>

<style scoped>
ion-img::part(image) {
    width: 165px;
    height: 165px;
    border-radius: 5px;
}

.profile-header {
    padding: 16px;
    display: flex;
}

.profile-details {
    margin-left: 16px;
}

@media (max-width: 768px) {
    ion-avatar img {
        width: 80px;
        height: 80px;
    }

    iframe {
        height: 200px;
    }
}
</style>
