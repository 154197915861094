<script setup lang="ts">
import Cookies from 'js-cookie';
import { onBeforeUnmount } from 'vue';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const languages = ref([
    { code: 'en', label: 'EN' },
    { code: 'ru', label: 'RU' },
]);
const switcherRef = ref<HTMLButtonElement | null>(null);

const changeLanguage = (lang: string) => {
    Cookies.set('language', lang, { expires: 365 });
    locale.value = lang;
    isDropdownOpen.value = false;
};

const isDropdownOpen = ref(false);

const toggleDropdown = () => {
    if (!isDropdownOpen.value) {
        positionDropdown();
    }
    isDropdownOpen.value = !isDropdownOpen.value;
};
const dropdownStyles = ref({
    top: '0px',
    left: '0px',
});

const closeDropdown = () => {
    isDropdownOpen.value = false;
};

const positionDropdown = () => {
    if (switcherRef.value) {
        const rect = switcherRef.value.getBoundingClientRect();
        dropdownStyles.value = {
            top: `${rect.bottom + window.scrollY - 35}px`,
            left: `${rect.left + window.scrollX}px`,
        };
    }
};

const handleClickOutside = (event: MouseEvent) => {
    if (switcherRef.value && !switcherRef.value.contains(event.target as Node)) {
        closeDropdown();
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside);

    const savedLanguage = Cookies.get('language') || 'en';
    if (savedLanguage) {
        locale.value = savedLanguage;
    }
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
    <div class="language-switcher">
        <button ref="switcherRef" @click="toggleDropdown" class="current-language">
            {{ languages.find((lang) => lang.code === locale)?.label }}
        </button>
        <teleport to="body">
            <ul v-if="isDropdownOpen" class="dropdown" :style="dropdownStyles">
                <li
                    v-for="lang in languages"
                    :key="lang.code"
                    :class="{ active: lang.code === locale }"
                    @click="changeLanguage(lang.code)"
                >
                    {{ lang.label }}
                </li>
            </ul>
        </teleport>
    </div>
</template>

<style scoped lang="scss">
@use '../../css/ui-kit.scss' as *;

.language-switcher {
    position: relative;
    display: inline-block;
}

.current-language {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.current-language:hover {
    background-color: #{$grey-200};
}

.dropdown {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    background-color: #{$grey-100};
    border: 1px solid #{$grey-700};
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown li {
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown li:hover {
}

.dropdown li.active {
}
</style>
