<script setup lang="ts">
import { ref, computed } from 'vue';

import RequestResetCode from './reset-password/RequestResetCode.vue';
import VerifyResetCode from './reset-password/VerifyResetCode.vue';
import LPage from '@/core/components/LPage.vue';
import { t } from '@/plugins/i18n';

const steps = [
    { name: 'requestResetCode', component: RequestResetCode },
    { name: 'verigtCode', component: VerifyResetCode },
];
const email = ref<string>('');
const currentStepIndex = ref(0);

const updateEmail = (newEmail: string) => {
    email.value = newEmail;
};

const nextStep = () => {
    if (currentStepIndex.value < steps.length - 1) {
        currentStepIndex.value += 1;
    }
};

const prevStep = () => {
    if (currentStepIndex.value > 0) {
        currentStepIndex.value -= 1;
    }
};

const currentComponent = computed(() => steps[currentStepIndex.value].component);
</script>

<template>
    <l-page>
        <div class="container px-4 pt-16">
            <div class="inner-container">
                <div class="mb-6">
                    <img style="width: 100px" src="/icons/logo.svg" alt="" class="mx-auto" />
                </div>

                <div class="caption-2 mb-6 text-center">{{ t('auth.reset.title') }}</div>
                <div class="body-2 mb-6 text-center">{{ t('auth.reset.subTitle') }}</div>

                <div class="w-full mb-4">
                    <component
                        :is="currentComponent"
                        :email="email"
                        @updateEmail="updateEmail"
                        @nextStep="nextStep"
                        @prevStep="prevStep"
                    />
                </div>
            </div>
        </div>
    </l-page>
</template>

<style scoped>
.container {
    .inner-container {
        margin: 0 auto;
        max-width: 450px;
    }
}
</style>
