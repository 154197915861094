<script setup lang="ts">
import { ref, computed } from 'vue';
import { IonButton, IonCard, IonCardContent, IonInput } from '@ionic/vue';
import { t } from '@/plugins/i18n';
import LResendCode from './LResendCode.vue';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { useAuthApi } from '../../api/auth.api';
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';

const authApi = useAuthApi();
const learnerStore = useLearnerStore();
const verificationCode = ref('');
const isCodeValid = computed(() => verificationCode.value.length >= 6);
const email = ref(learnerStore.profile.user.email);
const isCodeSent = ref(false);
const emit = defineEmits(['verification-success']);

const sendVerificationCode = async () => {
    isCodeSent.value = true;
    await authApi.verificationRequest('email');
};

const verifyCode = async () => {
    if (isCodeValid.value) {
        const result = await authApi.verificationValidate(verificationCode.value);
        if (result) {
            emit('verification-success');
        } else {
            verificationCode.value = '';
        }
    }
};
</script>

<template>
    <ion-card>
        <ion-card-content>
            <div class="verification-form">
                <h2>{{ t('learner.verification.title') }}</h2>

                <div class="input-container">
                    <l-input label="EMAIL:" v-model="email" placeholder="Email"></l-input>
                </div>

                <l-button v-if="!isCodeSent" @click="sendVerificationCode">
                    {{ t('learner.verification.sendCodeButton') }}
                </l-button>

                <div v-else>
                    <div class="input-container">
                        <l-input
                            v-model="verificationCode"
                            :placeholder="t('learner.verification.codePlaceholder')"
                        />
                    </div>
                    <l-button @click="verifyCode" :disabled="!isCodeValid">
                        {{ t('learner.verification.verifyButton') }}
                    </l-button>

                    <l-resend-code @resend="sendVerificationCode" />
                </div>
            </div>
        </ion-card-content>
    </ion-card>
</template>

<style scoped>
.verification-form {
    padding: 16px;
    text-align: center;
}

.input-container {
    max-width: 300px;
    margin: 0px auto;
    padding: 16px;
}
</style>
