<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { t } from '@/plugins/i18n';
import { IonButton } from '@ionic/vue';
import LButton from '@/core/components/LButton.vue';

const cooldown = ref(60);
const isCooldownActive = ref(false);

const emit = defineEmits(['resend']);

const resend = () => {
    isCooldownActive.value = true;
    emit('resend');
    startCooldown();
};

const startCooldown = () => {
    let startTime = Date.now();
    const waitingTime = 30;
    const updateCooldown = () => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        cooldown.value = waitingTime - elapsedTime;

        if (cooldown.value > 0) {
            setTimeout(updateCooldown, Math.max(1000 - ((Date.now() - startTime) % 1000), 0));
        } else {
            isCooldownActive.value = false;
            cooldown.value = waitingTime;
        }
    };

    updateCooldown();
};

onMounted(() => {
    startCooldown();
});
</script>

<template>
    <div class="resend-code">
        <l-button @click="resend" :disabled="isCooldownActive">
            {{ t('learner.verification.resendButton') }}
        </l-button>
        <p v-if="isCooldownActive">
            {{ t('learner.verification.waitResend') }} {{ cooldown }}
            {{ t('learner.verification.beforeResend') }}
        </p>
    </div>
</template>

<style scoped>
.resend-code {
    margin-top: 20px;
}
</style>
