import { defineStore } from 'pinia';
import { GetEventsParams, Meta, useLearnerApi, Event } from '../api/learner.api';

export const useCalendarStore = defineStore('calendarStore', {
    state: () => ({
        loading: false,
        lessons: [] as Event[],
        meta: null as Meta | null,
    }),

    actions: {
        async fetchLessons({ statuses, startTime, endTime }: GetEventsParams) {
            const learnerApi = useLearnerApi();
            this.loading = true;

            try {
                const response = await learnerApi.getLessons({
                    startTime,
                    endTime,
                    statuses,
                });

                if (response && response.data) {
                    this.lessons = response.data;
                }
            } catch (error) {
                console.error('Failed to fetch lessons:', error);
            } finally {
                this.loading = false;
            }
        },
    },
});
