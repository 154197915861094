<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { alertController, IonButton, IonInput, IonItem, IonLabel } from '@ionic/vue';
import { t } from '@/plugins/i18n';
import { AuthApi } from '../../api/auth.api';
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';
import LPasswordInput from '../LPasswordInput.vue';

const code = ref('');
const authApi = new AuthApi();
const newPassword = ref('');
const confirmPassword = ref('');
const isCodeVerified = ref(false);
const route = useRoute();
const router = useRouter();

const props = defineProps<{ email: string }>();

const emit = defineEmits(['prevStep', 'nextStep']);

const isPasswordValid = ref(false);

onMounted(() => {
    const queryCode = route.query.code as string;
    if (queryCode) {
        code.value = queryCode;
    }
});

const verifyCode = async () => {
    try {
        const isValid = await authApi.validateResetCode({ token: code.value, email: props.email });
        if (isValid) {
            isCodeVerified.value = true;
        } else {
            const alert = await alertController.create({
                header: t('auth.reset.codeErrorTitle'),
                message: t('auth.reset.codeErrorDescription'),
                buttons: [t('auth.reset.codeErrorButton')],
            });
            await alert.present();
            emit('prevStep');
        }
    } catch (error) {
        console.error('Error verifying code:', error);
    }
};

const resetValues = () => {
    confirmPassword.value = '';
    newPassword.value = '';
    code.value = '';
    isCodeVerified.value = false;
    emit('prevStep');
};

const handleValidation = (val: boolean) => {
    isPasswordValid.value = val;
};

const updatePassword = async () => {
    if (!isPasswordValid.value) {
        const alert = await alertController.create({
            header: t('auth.reset.newPasswordErrorTitle'),
            message: t('auth.reset.newPasswordErrorDescription'),
            buttons: [t('auth.reset.newPasswordErrorButton')],
        });
        await alert.present();
        return;
    }
    try {
        const isSuccessful = await authApi.resetPasswordConfirm({
            email: props.email,
            token: code.value,
            password: newPassword.value,
        });
        if (isSuccessful) {
            const alert = await alertController.create({
                header: t('auth.reset.successTitle'),
                message: t('auth.reset.successDescription'),
                buttons: [t('auth.reset.successButton')],
            });
            await alert.present();
            router.push('/login');
            resetValues();
        } else {
            const alert = await alertController.create({
                header: t('auth.reset.successTitle'),
                message: t('auth.reset.successDescription'),
                buttons: [t('auth.reset.successButton')],
            });
            await alert.present();
        }
    } catch (error) {
        console.error('Error updating password:', error);
    }
};
</script>

<template>
    <div v-if="!isCodeVerified">
        <label for="login" class="block caption-3 mb-1">{{ t('auth.reset.code') }}</label>
        <l-input v-model="code" :placeholder="t('auth.reset.codePlaceholder')" class="mb-4" />
        <l-button expand="block" @click="verifyCode">{{ t('auth.reset.getCode') }}</l-button>
    </div>

    <div v-else>
        <l-password-input v-model="confirmPassword" @validate="handleValidation" />

        <l-button class="mt-6" expand="block" @click="updatePassword" :disabled="!isPasswordValid">
            {{ t('auth.reset.updatePassword') }}
        </l-button>
    </div>
</template>
