import { createClientProxy } from '@/core/api/client-proxy';
import { paths } from '../../../../../../packages/api-client/src';

export type Learner =
    paths['/learner/profile']['get']['responses']['200']['content']['application/json'];
export type TutorCatalogResponse =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json'];
export type TutorCatalog =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json']['data'];
export type TutorProfile =
    paths['/learner/tutors/{tutorUserUid}']['get']['responses']['200']['content']['application/json'];
export type Tutor =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json']['data'][0];
export type Event =
    paths['/learner/events/']['get']['responses']['200']['content']['application/json']['data'][0];
export type Meta =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json']['meta'];
export type AvaliableSlot =
    paths['/learner/available-slots/']['get']['responses']['200']['content']['application/json']['data'][0];
export type ScheduleTrialParams =
    paths['/learner/trial-lesson/schedule']['post']['requestBody']['content']['application/json'];
export type GetAvaliableSlotsParams =
    paths['/learner/available-slots/']['get']['parameters']['query'];
export type GetEventsParams = paths['/learner/events/']['get']['parameters']['query'];
export type TutorsCatalogParams = paths['/learner/tutors/search']['get']['parameters']['query'];

export interface ITutorsCatalogResponse {
    data?: TutorCatalogResponse;
    success: boolean;
}
export interface IEventsResponse {
    data?: Event[];
    success: boolean;
}

export interface IAvaliableSlotsResponse {
    data?: AvaliableSlot[];
    success: boolean;
}

export interface IBookTrialResponse {
    success: boolean;
}

export interface IScheduleTrialLessonResponse {
    success: boolean;
}

const client = createClientProxy<paths>({
    errorNotify: false,
    errorThrow: false,
});

export class LearnerApi {
    async getLearnerProfile(): Promise<Learner> {
        const { data } = await client.GET('/learner/profile');
        return data!;
    }

    async getTutor(tutorUserUid: string): Promise<TutorProfile> {
        const { data } = await client.GET('/learner/tutors/{tutorUserUid}', {
            params: { path: { tutorUserUid } },
        });
        return data!;
    }

    async getCatalogOfTutors({
        page,
        pageSize,
        subject,
    }: TutorsCatalogParams): Promise<ITutorsCatalogResponse> {
        const { data, error } = await client.GET('/learner/tutors/search', {
            params: { query: { page, pageSize, subject } },
        });

        return {
            data,
            success: !error,
        };
    }

    async getLessons({ startTime, endTime, statuses }: GetEventsParams): Promise<IEventsResponse> {
        const { data, error } = await client.GET('/learner/events/', {
            params: { query: { startTime, endTime, statuses } },
        });
        return {
            data: data?.data,
            success: !error,
        };
    }

    async getTutorAvailableSlots({
        startTime,
        endTime,
        tutorUserUid,
    }: GetAvaliableSlotsParams): Promise<IAvaliableSlotsResponse> {
        const { data, error } = await client.GET('/learner/available-slots/', {
            params: { query: { startTime, endTime, tutorUserUid } },
        });
        return {
            data: data?.data,
            success: !error,
        };
    }

    async scheduleTrialLesson(params: ScheduleTrialParams): Promise<IScheduleTrialLessonResponse> {
        const { endTime, startTime, tutorUserUid, subjectCode } = params;
        const { error } = await client.POST('/learner/trial-lesson/schedule', {
            body: { endTime, startTime, tutorUserUid, subjectCode },
        });
        return {
            success: !error,
        };
    }
}

export const useLearnerApi = () => new LearnerApi();
