<script setup lang="ts">
import { alertController, IonButton, IonIcon, IonText } from '@ionic/vue';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { SignUpRequestDto } from '../api/auth.api';
import LPage from '@/core/components/LPage.vue';
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';
import LPasswordInput from '../components/LPasswordInput.vue';

const router = useRouter();
const route = useRoute();

const { t } = useI18n();

const authStore = useAuthStore();

const isPasswordValid = ref(false);

// Define the reactive signup form data using ref
const signupForm = ref({
    email: '',
    password: '',
} as SignUpRequestDto);

// Fetch role from query parameters on page load
onMounted(() => {
    const roleParam = route.query.role;
    if (roleParam === 'tutor' || roleParam === 'learner') {
        signupForm.value.role = roleParam;
    }
});

const handleValidation = (isValid: boolean) => {
    isPasswordValid.value = isValid;
};

const isSubmitAllowed = () => {
    return !!signupForm.value.email && !!signupForm.value.password && isPasswordValid.value;
};

const onSignup = async () => {
    if (!signupForm.value.email || !signupForm.value.password) {
        const alert = await alertController.create({
            header: 'Signup Error',
            message: 'Please enter both email and password.',
            buttons: ['OK'],
        });
        await alert.present();
        return;
    }

    // Prepare the signup data
    const signupData = {
        email: signupForm.value.email,
        password: signupForm.value.password,
    } as SignUpRequestDto;

    // Add role only if it's defined
    if (signupForm.value.role) {
        signupData.role = signupForm.value.role;
    }

    const success = await authStore.signUp(signupData);
    if (success) {
        router.push('/');
    } else {
        const alert = await alertController.create({
            header: 'Signup Error',
            message: 'There was an error creating your account.',
            buttons: ['OK'],
        });
        await alert.present();
    }
};

const toLogin = () => {
    router.push('/login');
};
</script>

<template>
    <div class="container px-4 pt-16">
        <div class="inner-container">
            <div class="mb-6">
                <img style="width: 100px" src="/icons/logo.svg" alt="" class="mx-auto" />
            </div>

            <div class="caption-2 mb-1 text-center">{{ t('auth.signup.title') }}</div>
            <div class="body-2 mb-6 text-center">{{ t('auth.signup.subTitle') }}</div>

            <div class="w-full mb-4">
                <label for="login" class="block caption-3 mb-1">{{
                    t('auth.signup.emailLabel')
                }}</label>
                <l-input
                    v-model="signupForm.email"
                    type="email"
                    :placeholder="t('auth.login.emailPlaceholder')"
                >
                </l-input>
            </div>

            <div class="w-full mb-8">
                <label for="login" class="block caption-3 mb-1">{{
                    t('auth.signup.passwordLabel')
                }}</label>
                <l-password-input v-model="signupForm.password" @validate="handleValidation" />
            </div>

            <!-- <div class="w-full mb-6">
        <div class="flex justify-between">
          <label for="login" class="block caption-3 mb-1">{{ t('auth.signup.role') }}</label>
          <ion-text for="login" class="block caption-3 mb-1 grey-500">{{ t('auth.signup.optional') }}</ion-text>
        </div>
        <l-input
          v-model='signupForm.role'
          type='email'
          :placeholder="t('rolePlaceholder')"
          disabled
          >
        </l-input>
      </div> -->

            <l-button
                color="primary"
                fill="default"
                class="w-full"
                :disabled="!isSubmitAllowed()"
                @click="onSignup"
            >
                {{ t('auth.signup.submit') }}
            </l-button>

            <p class="body-3 text-center mt-1">
                {{ t('auth.signup.policyQuestion') }}
                <a href="#" class="underline">{{ t('auth.signup.termsOfService') }}</a>
                {{ t('auth.signup.and') }}
                <a href="#" class="underline">{{ t('auth.signup.privacyPolicy') }}</a
                >.
            </p>

            <div class="text-center w-full mt-6 caption-4">
                <p>
                    Already have an acccount?
                    <a href="#" @click.prevent="toLogin" class="purple-primary">{{
                        t('auth.login.submit')
                    }}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.container {
    .inner-container {
        margin: 0 auto;
        max-width: 450px;
    }
}

.purple-primary {
    color: var(--ion-color-success);
}

.grey-500 {
    color: $grey-500;
}

.helpers {
    ion-card {
        margin-bottom: 8px;
    }
}
</style>
