export const pluralizationRules = {
    ru(choice: number) {
        if (choice === 0) {
            return 0;
        }
        if (choice === 1 || choice % 10 === 1) {
            return 1;
        }
        if (choice <= 4) {
            return 2;
        }
        if (choice % 10 <= 4) {
            return 2;
        }
        return 0;
    },
};
