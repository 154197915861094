<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import { t } from '@/plugins/i18n';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import LButton from '@/core/components/LButton.vue';

const format = useLocaleDateFormat();

const props = defineProps({
    availableSlots: {
        type: Array<string>,
        required: true,
    },
});
const emit = defineEmits(['selectTime']);
const selectedSlot = ref(null) as Ref<string | null>;

const morningSlots = computed(() => {
    return props.availableSlots.filter((slot) => {
        return parseInt(format(new Date(slot), 'HH')) < 12;
    });
});

const daySlots = computed(() => {
    return props.availableSlots.filter((slot) => parseInt(format(new Date(slot), 'HH')) >= 12);
});

const selectSlot = (slot: string) => {
    selectedSlot.value = slot;
    emit('selectTime', slot);
};

const isSelectedSlot = (slot: string) => {
    return selectedSlot.value === slot;
};
</script>

<template>
    <div class="l-time-slots">
        <div v-show="morningSlots.length > 0" class="section">
            <ion-text class="body-2">{{ t('learner.booking.am') }}</ion-text>
            <div class="time-options">
                <l-button
                    v-for="slot in morningSlots"
                    :key="slot"
                    :fill="isSelectedSlot(slot) ? 'default' : 'outline'"
                    @click="selectSlot(slot)"
                    :color="isSelectedSlot(slot) ? 'success' : 'secondary'"
                >
                    {{ format(new Date(slot), 'HH:mm') }}
                </l-button>
            </div>
        </div>

        <div v-show="daySlots.length > 0" class="section">
            <ion-text class="body-2">{{ t('learner.booking.pm') }}</ion-text>
            <div class="time-options caption-3">
                <l-button
                    v-for="slot in daySlots"
                    :key="slot"
                    :fill="isSelectedSlot(slot) ? 'default' : 'outline'"
                    @click="selectSlot(slot)"
                    :color="isSelectedSlot(slot) ? 'success' : 'secondary'"
                >
                    {{ format(new Date(slot), 'HH:mm') }}
                </l-button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.l-time-slots .section {
    margin-bottom: 16px;
    text-align: center;
}

.time-options button {
    width: 80;
    height: 40;
    margin-right: 8px;
}
</style>
