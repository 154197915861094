<script setup lang="ts">
import { ref, useTemplateRef, watch } from 'vue';
import { alertController, IonInput, IonItem, IonButton } from '@ionic/vue';
import { emailRegExp } from '@common/utils/email-regexp';
import { t } from '@/plugins/i18n';
import { AuthApi } from '../../api/auth.api';
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';
import LFeedback from '@/core/components/LFeedback.vue';

const isEmailValid = ref(false);
const isEmailDangerVisible = ref(false);
const props = defineProps<{ email: string }>();
const localEmail = ref(props.email);
const emit = defineEmits(['updateEmail', 'nextStep']);

const authApi = new AuthApi();

const inputRef = useTemplateRef('input');

watch(localEmail, (newEmail) => {
    emit('updateEmail', newEmail);
});

const validateEmail = (emailValue: string) => {
    const result = emailValue.match(emailRegExp);

    return result;
};

const validate = (ev: Event) => {
    const { value } = ev.target as HTMLInputElement;

    inputRef.value?.$el.classList.remove('ion-valid');
    inputRef.value?.$el.classList.remove('ion-invalid');

    if (value === '') {
        isEmailValid.value = false;
        return;
    }

    if (validateEmail(value)) {
        inputRef.value?.$el.classList.add('ion-valid');
        isEmailValid.value = true;
    } else {
        inputRef.value?.$el.classList.add('ion-invalid');
        isEmailValid.value = false;
    }
};

const markTouched = () => {
    inputRef.value?.$el.classList.add('ion-touched');
};

const sendResetCode = async () => {
    if (!isEmailValid.value) {
        isEmailDangerVisible.value = true;
        return;
    }
    isEmailDangerVisible.value = false;

    try {
        if (localEmail.value) {
            const isCodeSent = await authApi.sendResetPasswordEmail(localEmail.value);
            if (isCodeSent) {
                emit('nextStep');
                localEmail.value = '';
            } else {
                const alert = await alertController.create({
                    header: t('auth.reset.emailErrorTitle'),
                    message: t('auth.reset.emailErrorDescription'),
                    buttons: [t('auth.reset.emailErrorButton')],
                });
                await alert.present();
            }
        }
    } catch (error) {
        console.error('Error sending reset code:', error);
    }
};
</script>

<template>
    <label for="email" class="block caption-3 mb-1">{{ t('auth.signup.emailLabel') }}</label>
    <l-input
        ref="input"
        v-model="localEmail"
        type="email"
        :placeholder="t('auth.login.emailPlaceholder')"
        @ionInput="validate"
        @ionBlur="markTouched"
    />
    <l-feedback
        color="danger-tint"
        :class="`mt-1 ${isEmailDangerVisible ? 'visible' : 'invisible'}`"
    >
        {{ t('auth.reset.incorrentEmailDanger') }}
    </l-feedback>
    <l-button expand="block" class="mt-6" @click="sendResetCode">{{
        t('auth.reset.getCode')
    }}</l-button>
</template>

<style scoped>
.invisible {
    opacity: 0;
}

.visible {
    opacity: 1;
}
</style>
