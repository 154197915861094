<script setup lang="ts">
import {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
    IonText,
} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { calendar, checkmarkCircle, heartOutline, star } from 'ionicons/icons';
import { defineProps } from 'vue';
import { Tutor } from '../../api/learner.api';
import { useTutorCatalogStore } from '../../store/tutors-catalog.store';
import { t } from '@/plugins/i18n';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { ref } from 'vue';
import { useLearnerStore } from '../../store/learner.store';
import { computed } from 'vue';
import LButton from '@/core/components/LButton.vue';

const props = defineProps<{ tutor: Tutor; index: number }>();
const { tutor, index } = props;
const { isSmallSize } = useScreenSize();

const localePref = 'learner.tutorCatalog.tutorCard';

const router = useRouter();
const route = useRoute();

const descriptionRef = ref<HTMLElement | null>(null);
const isExpanded = ref(false);

const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
};

const tutorStore = useTutorCatalogStore();
const learnerStore = useLearnerStore();
const { setActiveTutor } = tutorStore;
const emit = defineEmits(['hoverCard', 'openModal']);
const maxCountSimbols = 120;
const isTrial = computed(() => learnerStore.isScheduleTrial);

const onMouseEnter = (event: MouseEvent) => {
    setActiveTutor(index);
    const card = event.target as HTMLElement;
    const position = {
        top: card.offsetTop - 15,
    };

    emit('hoverCard', position);
};

const onOpenModal = () => {
    emit('openModal');
};
const onClick = async () => {
    if (route.path.includes('trial-')) {
        await router.push(`/trial-catalog/${tutor.userUid}`);
    } else {
        await router.push(`/learner/tutor/${tutor.userUid}`);
    }
};
</script>

<template>
    <ion-card
        :id="`ion-card-${tutor.userUid}`"
        @mouseenter="onMouseEnter"
        @click="onClick"
        class="tutor-card"
    >
        <div v-if="isSmallSize">
            <ion-row>
                <ion-col>
                    <ion-row>
                        <!-- Photo -->
                        <ion-col size="4">
                            <ion-img :src="tutor.profilePhoto" alt="Tutor photo" />
                        </ion-col>
                        <!-- Name -->
                        <ion-col size="8">
                            <ion-row>
                                <p class="caption-3">{{ tutor.name }}</p>
                                <ion-icon
                                    style="margin-left: 8px"
                                    size="small"
                                    color="success"
                                    :icon="checkmarkCircle"
                                />
                            </ion-row>
                            <ion-row>
                                <p class="caption-3">
                                    <ion-icon :icon="star" color="dark" slot:icon-only /> 5.0
                                </p>
                                <p class="body-2" style="margin-left: 5px">43 отзыва</p>
                            </ion-row>
                            <ion-row>
                                <div class="price-container">
                                    {{ t(`${localePref}.from`) }}
                                    <strong class="caption-2"> {{ tutor.priceFromUsd }} </strong>
                                    {{ t(`${localePref}.lessonPrice`) }}
                                </div>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <!-- Description -->
                        <ion-col
                            class="tutor-card-description"
                            :class="{ expanded: isExpanded }"
                            ref="descriptionRef"
                        >
                            <p>
                                {{ tutor.profileDescription }}
                                <ion-text
                                    v-if="tutor.profileDescription.length > maxCountSimbols"
                                    class="show-more-button"
                                    size="small"
                                    @click.stop="toggleExpand"
                                >
                                    {{ isExpanded ? 'Скрыть' : `Подробнее` }}
                                </ion-text>
                            </p>
                        </ion-col>
                    </ion-row>
                    <div class="button-container">
                        <l-button expand="block" fill="outline" color="tertiary">
                            <ion-text color="dark">
                                <ion-icon :icon="calendar" slot:icon-only></ion-icon> Расписание
                            </ion-text>
                        </l-button>
                        <l-button
                            expand="block"
                            color="primary"
                            fill="default"
                            :disabled="!isTrial"
                            class="caption-3"
                            size="default"
                            @click.stop="onOpenModal"
                        >
                            {{ t(`${localePref}.scheduleTrialLesson`) }}
                        </l-button>
                    </div>
                </ion-col>
            </ion-row>
            <ion-icon :icon="heartOutline" class="favorite" color="dark"></ion-icon>
        </div>
        <div v-else>
            <ion-grid>
                <ion-row>
                    <ion-col size="8">
                        <ion-row>
                            <!-- Photo -->
                            <ion-col>
                                <ion-img :src="tutor.profilePhoto" alt="Tutor photo" />
                            </ion-col>
                            <!-- Name -->
                            <ion-col>
                                <ion-card-title
                                    >{{ tutor.name }}
                                    <ion-icon
                                        style="margin-left: 8px"
                                        color="success"
                                        :icon="checkmarkCircle"
                                    />
                                </ion-card-title>
                                <ion-card-subtitle>{{ tutor.profileTitle }}</ion-card-subtitle>
                                <!-- Experience -->
                                <p>
                                    {{ t(`${localePref}.from`) }}
                                    <strong class="caption-2"> {{ tutor.priceFromUsd }} </strong>
                                    {{ t(`${localePref}.lessonPrice`) }}
                                </p>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col
                        size="4"
                        style="
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        "
                    >
                        <ion-row style="display: flex; flex-direction: column">
                            <div class="rate-container">
                                5.0 (43 отзыва)
                                <l-button fill="clear" color="secondary">
                                    <ion-icon :icon="heartOutline" slot:icon-only></ion-icon>
                                </l-button>
                            </div>
                        </ion-row>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <!-- Description -->
                    <ion-col
                        class="tutor-card-description"
                        :class="{ expanded: isExpanded }"
                        ref="descriptionRef"
                    >
                        <p>{{ tutor.profileDescription }}</p>
                        <ion-text
                            v-if="tutor.profileDescription.length > maxCountSimbols"
                            class="show-more-button"
                            size="small"
                            @click.stop="toggleExpand"
                        >
                            {{
                                isExpanded
                                    ? t(`${localePref}.showLess`)
                                    : t(`${localePref}.showMore`)
                            }}
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="6">
                        <div class="full-profile-link">
                            <ion-text color="success">
                                <p>{{ t(`${localePref}.toFullProfile`) }} <b>→</b></p>
                            </ion-text>
                        </div>
                    </ion-col>
                    <ion-col size="6" @click.stop>
                        <div style="display: flex; justify-content: flex-end">
                            <l-button
                                color="primary"
                                fill="default"
                                :disabled="!isTrial"
                                @click.stop="onOpenModal"
                            >
                                {{ t(`${localePref}.scheduleTrialLesson`) }}
                            </l-button>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ion-card>
</template>

<style scoped>
.tutor-card {
    padding-top: 5px;
    position: relative;
}

.tutor-card-description {
    .show-more-button {
        background-color: white;
        padding: 4px 10px 4px 3px;
        position: absolute;
        bottom: 0px;
        color: var(--ion-color-success);
        font-size: 15px;
        right: 0;
    }

    max-height: 50px;
    overflow: hidden;
    text-overflow: clip;
    position: relative;
}

.tutor-card-description.expanded {
    height: auto;
    overflow: visible;

    .show-more-button {
        display: block;
        text-align: end;
        position: relative;
    }
}

.favorite {
    position: absolute;
    top: 13px;
    right: 8px;
    font-size: 24px;
}

.rate-container {
    text-align: end;
}

.price-container {
    margin-top: 16px;
}

.full-profile-link {
    cursor: pointer;
    display: flex;

    &:hover {
        text-decoration: underline;
    }
}

.button-container {
    .button {
        margin-bottom: 16px;
    }
}

ion-img::part(image) {
    width: 165px;
    height: 165px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    ion-img::part(image) {
        width: 76px;
        height: 76px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 992px) {
    ion-img::part(image) {
        width: 90px;
        height: 90px;
        border-radius: 5px;
    }
}
</style>
