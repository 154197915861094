<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import { onMounted } from 'vue';
import { useChatStore } from '@/modules/chat/store/chat.store';
import LChats from '@/modules/chat/components/LChats.vue';

const chatStore = useChatStore();

async function fetchData() {
    await chatStore.fetchChats();
}

onMounted(fetchData);
</script>

<template>
    <l-page>
        <l-chats />
    </l-page>
</template>
