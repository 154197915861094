<script setup lang="ts">
import {
    IonContent,
    IonToolbar,
    IonTitle,
    IonRow,
    IonPage,
    IonIcon,
    IonFooter,
    IonModal,
    IonButtons,
    IonSpinner,
    IonRouterOutlet,
} from '@ionic/vue';
import { t } from '@/plugins/i18n';
import LLanguageSwitcher from '../components/LLanguageSwitcher.vue';
import { chatbubblesOutline, personOutline } from 'ionicons/icons';
import { ref } from 'vue';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { useRouter } from 'vue-router';
import LButton from '../components/LButton.vue';
import { useI18n } from 'vue-i18n';

const isOpenProfile = ref(false);
const isOpenChat = ref(false);
const authStore = useAuthStore();
const router = useRouter();

const setOpenProfileModal = (open: boolean) => (isOpenProfile.value = open);
const setOpenChatModal = (open: boolean) => (isOpenChat.value = open);

const { locale } = useI18n();

const onLogout = async () => {
    await authStore.logout();
    router.push('/login');
};
</script>

<template>
    <ion-page>
        <ion-toolbar>
            <ion-row class="onboarding-header">
                <div class="logo-container">
                    <img style="height: 36px" src="/icons/logo.svg" alt="" />
                </div>
                <div class="profile-button" @click="setOpenProfileModal(true)">
                    {{ locale }}
                </div>
            </ion-row>
        </ion-toolbar>

        <ion-content class="ion-padding">
            <ion-router-outlet />
        </ion-content>

        <ion-footer collapse="fade">
            <ion-toolbar class="footer-toolbar" @click="setOpenChatModal(true)">
                <div class="footer-toolbar">
                    <ion-icon
                        style="margin-right: 8px"
                        size="small"
                        :icon="chatbubblesOutline"
                    ></ion-icon>
                    {{ t('learner.onboarding.footer') }}
                </div>
            </ion-toolbar>
        </ion-footer>

        <ion-modal :is-open="isOpenProfile">
            <ion-toolbar>
                <ion-title>PROFILE</ion-title>
                <ion-buttons slot="end">
                    <l-button @click="setOpenProfileModal(false)">Close</l-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-content class="ion-padding">
                <ion-row>
                    <l-language-switcher />
                    <l-button color="danger" @click="onLogout">{{ t('auth.logout') }}</l-button>
                </ion-row>
            </ion-content>
        </ion-modal>

        <ion-modal :is-open="isOpenChat">
            <ion-toolbar>
                <ion-title>CHAT</ion-title>
                <ion-buttons slot="end">
                    <l-button @click="setOpenChatModal(false)">Close</l-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-content class="ion-padding">
                <ion-row>
                    <ion-spinner />
                    TODO
                    <ion-spinner />
                </ion-row>
            </ion-content>
        </ion-modal>
    </ion-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.onboarding-header {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    background-color: $grey-100;
    border-bottom: 1px solid $grey-400;
}

.footer-toolbar {
    background-color: $grey-100;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $grey-400;
}

.language-select {
    margin-right: 26px;
}

.profile-button {
    text-transform: uppercase;
    border: 2px solid $grey-400;
    border-radius: $default-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}
</style>
