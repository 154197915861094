import { createRouter, createWebHistory } from '@ionic/vue-router';
import { authGuard } from './guards/auth.guard';
import { routes } from './routes';
import { alreadyLoggedInGuard } from './guards/already-logged-in.guard';
import { selectRoleGuard } from './guards/select-role.guard';
import { learnerOnboardingGuard } from './guards/learner-onboarding.guard';
import { tutorOnboardingGuard } from './guards/tutor-onboarding.guard';
import { roleGuard } from './guards/role-guard';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(authGuard);
router.beforeEach(alreadyLoggedInGuard);
router.beforeEach(selectRoleGuard);
router.beforeEach(learnerOnboardingGuard);
router.beforeEach(tutorOnboardingGuard);
router.beforeEach(roleGuard);

export default router;
