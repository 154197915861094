import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { setupFirebase } from './boot/firebase';
import { setupIonic } from './boot/ionic';
import router from './router';
import { setupWorker } from './boot/worker';
import { setupI18n } from './boot/i18n';
import { setupSentry } from './boot/sentry';
import { createSentryPiniaPlugin } from '@sentry/vue';

const pinia = createPinia();
pinia.use(createSentryPiniaPlugin());

const app = createApp(App).use(pinia).use(router);
setupSentry(app, router);
setupFirebase(app);
setupWorker(app);
setupI18n(app);
setupIonic(app);

router.isReady().then(() => {
    app.mount('#app');
});
