<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import { IonInput } from '@ionic/vue';
import { Color } from '@ionic/core';

const props = defineProps<{
    modelValue?: string | number | null;
    type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url';
    placeholder?: string;
    color?: Color;
    disabled?: boolean;
    clearInput?: boolean;
    debounce?: number;
    label?: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void;
    (e: 'input', value: InputEvent): void;
    (e: 'blur', value: FocusEvent): void;
}>();

const handleInput = (event: CustomEvent) => {
    const target = event.target as HTMLIonInputElement;
    emit('update:modelValue', target.value || null);
    emit('input', event as any);
};

const handleBlur = (event: any) => {
    emit('blur', event);
};
</script>
<template>
    <ion-input
        mode="ios"
        :color="props.color || 'tertiary'"
        class="link-input"
        :label-placement="props.label ? 'floating' : undefined"
        :type="props.type || 'text'"
        :placeholder="props.placeholder || ''"
        :disabled="props.disabled || false"
        :clear-input="props.clearInput || false"
        :debounce="props.debounce || 0"
        :value="props.modelValue"
        @ionInput="handleInput"
        @ion-blur="handleBlur"
    >
        <slot />
    </ion-input>
</template>

<style lang="scss" scoped>
ion-input.link-input {
    label {
        border-radius: 8px !important;
        min-height: 48px !important;

        .label-text-wrapper {
            max-height: 14px;
            font-size: 16px;
        }
    }

    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 0px;

    .sc-ionic-input-ios-s > [slot='start']:last-of-type {
        margin-inline-end: 8px;
    }

    .sc-ionic-input-ios-s > [slot='end']:first-of-type {
        margin-inline-start: 8px;
    }
}
</style>
