<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import OnboardingLayout from '@/core/layouts/OnboardingLayout.vue';
import { t } from '@/plugins/i18n';
import LTutorList from '../components/tutors-search/LTutorList.vue';
import LPage from '@/core/components/LPage.vue';

const authStore = useAuthStore();
const router = useRouter();

const onLogout = async () => {
    await authStore.logout();
    router.push('/login');
};
</script>

<template>
    <onboarding-layout :title="t('learner.onboarding.title')" :on-logout="onLogout">
        <l-page>
            <l-tutor-list />
        </l-page>
    </onboarding-layout>
</template>
