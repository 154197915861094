<script setup lang="ts">
import { computed } from 'vue';
import {
    IonCard,
    IonBadge,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
} from '@ionic/vue';
import { differenceInMinutes } from 'date-fns';
import {
    calendarOutline,
    checkmarkCircleOutline,
    closeCircleOutline,
    hourglassOutline,
    timeOutline,
} from 'ionicons/icons';
import { Event } from '../../api/learner.api';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';

const format = useLocaleDateFormat();

const props = defineProps<{ lesson: Event }>();
const lesson = props.lesson;

const formattedDate = computed(() => format(new Date(lesson.startTime as string), 'MMMM d'));
const formattedTime = computed(() => format(new Date(lesson.startTime as string), 'HH:mm'));
const duration = computed(() =>
    differenceInMinutes(new Date(lesson.endTime as string), new Date(lesson.startTime as string)),
);

const lessonStatusIcon = computed(() => {
    switch (lesson.status) {
        case 'completed':
            return checkmarkCircleOutline;
        case 'canceled':
            return closeCircleOutline;
        case 'scheduled':
            return calendarOutline;

        default:
            break;
    }
});

// change after backend update
// todo mock data
const subject = 'Math';
const tutorImage =
    'https://media.istockphoto.com/id/1407084622/photo/portrait-of-smiling-mature-women.jpg?s=2048x2048&w=is&k=20&c=jIrOnPDipV2pEgF2SZfjT9aBxzZ1strbM7jMcvvk6HA='; // mock data
const tutorName = 'Mrs Brown'; // mock data
</script>

<template>
    <ion-card>
        <ion-card-header>
            <div class="event-date">
                <ion-icon :icon="lessonStatusIcon"></ion-icon>
                <ion-card-title>{{ formattedDate }}</ion-card-title>
            </div>
            <ion-badge color="light"> {{ subject }}</ion-badge>
        </ion-card-header>
        <ion-card-content>
            <div class="lesson-info">
                <div class="lesson-detail">
                    <ion-icon :icon="timeOutline" />
                    <span>{{ formattedTime }}</span>
                </div>
                <div class="lesson-detail">
                    <ion-icon :icon="hourglassOutline" />
                    <span>{{ duration }} min</span>
                </div>
            </div>
            <div class="tutor-info">
                <img :src="tutorImage" alt="Tutor image" />
                <span>{{ tutorName }}</span>
            </div>
        </ion-card-content>
    </ion-card>
</template>

<style scoped>
ion-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ion-badge {
        padding: 8px;
    }
}

.event-date {
    display: flex;
    flex-direction: row;
    align-items: center;

    ion-icon {
        font-size: 20px;
        margin-right: 10px;
    }

    ion-card-title {
        margin: 0;
    }
}

.lesson-detail {
    display: flex;
    align-items: center;
}

.lesson-detail span {
    margin-left: 5px;
}

.lesson-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.tutor-info {
    display: flex;
    align-items: center;
}

.tutor-info img {
    border-radius: 5px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
</style>
