import Cookies from 'js-cookie';

export const selectRoleGuard = () => {
    const role = Cookies.get('role');
    if (role === 'new') {
        return '/select-role';
    }

    return true;
};
