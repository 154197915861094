import OnboardingLayout from '@/core/layouts/OnboardingLayout.vue';
import ChatPage from '@/modules/chat/pages/ChatPage.vue';
import ChatsPage from '@/modules/chat/pages/ChatsPage.vue';
import LearnerLayout from '@/modules/learner/layouts/LearnerLayout.vue';
import CalendarPage from '@/modules/learner/pages/CalendarPage.vue';
import CatalogOfTutorsPage from '@/modules/learner/pages/CatalogOfTutorsPage.vue';
import LearnerOnboardingPage from '@/modules/learner/pages/LearnerOnboardingPage.vue';
import LearnerOnboardingSearch from '@/modules/learner/pages/LearnerOnboardingSearch.vue';
import LessonConfirmationPage from '@/modules/learner/pages/LessonConfirmationPage.vue';
import TutorPage from '@/modules/learner/pages/TutorPage.vue';
import HomePage from '@/pages/HomePage.vue';
import { RouteRecordRaw } from 'vue-router';

export const learnerRoutes: RouteRecordRaw[] = [
    {
        path: '/learner',
        component: LearnerLayout,
        children: [
            {
                path: '',
                redirect: '/learner/home',
            },
            {
                path: 'home',
                name: 'LearnerHome',
                component: HomePage,
            },
            {
                path: 'catalog',
                name: 'LearnerSearch',
                component: CatalogOfTutorsPage,
            },
            {
                path: 'calendar',
                name: 'LearnerCalendar',
                component: CalendarPage,
            },
            {
                path: 'tutor/:userUid',
                name: 'TutorPage',
                component: TutorPage,
                props: true,
            },
            {
                path: 'chats',
                name: 'LearnerChats',
                component: ChatsPage,
            },
        ],
    },
    {
        path: '/trial-catalog',
        name: 'trialCatalog',
        component: LearnerOnboardingSearch,
    },
    {
        path: '/trial-catalog/:userUid',
        name: 'TrialTutorPage',
        component: TutorPage,
        props: true,
    },
    {
        path: '/trial-lesson-confirmation',
        name: 'LessonConfirmationPage',
        component: LessonConfirmationPage,
        props: (route) => ({
            uid: route.query.uid,
            start: route.query.start,
            end: route.query.end,
        }),
    },
    {
        path: '/learner/onboarding',
        component: OnboardingLayout,
        children: [
            {
                path: 'survey',
                name: 'LearnerSurvey',
                component: LearnerOnboardingPage,
            },
        ],
    },
    {
        path: '/learner/chats/:chatUid',
        name: 'LearnerChat',
        component: ChatPage,
    },
];
