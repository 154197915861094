<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import {
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonLabel,
    IonSpinner,
    IonModal,
} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { useTutorCatalogStore } from '../../store/tutors-catalog.store';
import LTutorCard from './LTutorCard.vue';
import LTutorPreview from './LTutorPreview.vue';
import { t } from '@/plugins/i18n';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LBookingCalendar from '@/modules/learner/components/booking/LBookingCalendar.vue';
import LButton from '@/core/components/LButton.vue';

const { isSmallSize } = useScreenSize();
const localePref = 'learner.tutorCatalog';
const tutorsStore = useTutorCatalogStore();

const route = useRoute();
const router = useRouter();

const page = ref(route.query.page ? Number(route.query.page) : 1);
const activeCardPosition = ref(0);
const isOpenBookingModal = ref(false);

const subject = 'english';
const { currentPage, totalPages, tutors, isLoading, activeTutor } = storeToRefs(tutorsStore);

onMounted(async () => {
    await tutorsStore.fetchTutors('english', page.value);
});

watch(route, async () => {
    if (route.path.includes('catalog')) {
        page.value = Number(route.query.page) || 1;
        await tutorsStore.fetchTutors(subject, page.value);
    }
});

const updateQueryParams = (newPage: number) => {
    router.push({ query: { page: newPage } });
};

const handleHoverCard = (position: { top: number; left: number }) => {
    activeCardPosition.value = position.top + 5; // padding ;
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        updateQueryParams(currentPage.value + 1);
    }
};

const prevPage = () => {
    if (currentPage.value > 1) {
        updateQueryParams(currentPage.value - 1);
    }
};

const openModal = () => {
    isOpenBookingModal.value = true;
};

const closeModal = () => {
    isOpenBookingModal.value = false;
};
</script>

<template>
    <ion-modal
        v-if="tutors && activeTutor !== null"
        :isOpen="isOpenBookingModal"
        :breakpoints="[0.5, 0.8, 1]"
        :initial-break="isSmallSize ? 1 : 0.8"
        @did-dismiss="closeModal"
    >
        <l-booking-calendar
            @closeModal="closeModal"
            :tutor-user-uid="tutors[activeTutor].userUid"
        />
    </ion-modal>

    <div v-show="isLoading">
        <ion-spinner />
    </div>
    <div v-if="!isLoading && tutors?.length === 0">
        <p>{{ t(`${localePref}.noTutorsFound`) }}</p>
    </div>
    <div v-else-if="!isLoading">
        <ion-grid>
            <ion-row>
                <ion-col size-xl="9" size-lg="8" size-md="7" size-sm="12" size-xs="12">
                    <div class="left-column">
                        <l-tutor-card
                            v-for="(tutor, index) in tutors"
                            :key="tutor.userUid"
                            :index="index"
                            :tutor="tutor"
                            @openModal="openModal"
                            @hoverCard="handleHoverCard"
                        />
                    </div>
                </ion-col>
                <ion-col v-show="!isSmallSize" size="3">
                    <l-tutor-preview :activeCardPosition="activeCardPosition" />
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-footer>
            <ion-buttons>
                <l-button @click="prevPage" :disabled="currentPage === 1"
                    >{{ t(`${localePref}.prevPage`) }}
                </l-button>
                <ion-label>{{ currentPage }} / {{ totalPages }}</ion-label>
                <l-button @click="nextPage" :disabled="currentPage === totalPages"
                    >{{ t(`${localePref}.nextPage`) }}
                </l-button>
            </ion-buttons>
        </ion-footer>
    </div>
</template>

<style scoped>
ion-col {
    margin: 0px;
    padding: 0px;
}

ion-footer {
    display: flex;
    justify-content: center;
    padding: 10px;
}

@media screen and (max-width: 767px) {
    ion-grid {
        padding: 4px;
    }
}
</style>
