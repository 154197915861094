<script setup lang="ts">
import {
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonTabs,
    IonRouterOutlet,
    IonPage,
    IonHeader,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
} from '@ionic/vue';

import {
    homeOutline,
    chatbubbleOutline,
    calendarOutline,
    peopleOutline,
    schoolOutline,
} from 'ionicons/icons';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LPage from '@/core/components/LPage.vue';
import { useTutorStore } from '../store/tutor.store';
import LLanguageSwitcher from '@/core/components/LLanguageSwitcher.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { isSmallSize } = useScreenSize();
const { t } = useI18n();

const menuItems = computed(() => [
    {
        tab: 'home',
        href: '/tutor/home',
        icon: homeOutline,
        label: t('tutor.layout.homeButton'),
    },
    {
        tab: 'search',
        href: '/tutor/my-learners',
        icon: peopleOutline,
        label: t('tutor.layout.myLearnersButton'),
    },
    {
        tab: 'chats',
        href: '/tutor/chats',
        icon: chatbubbleOutline,
        label: t('tutor.layout.chatsButton'),
    },
    {
        tab: 'calendar',
        href: '/tutor/calendar',
        icon: calendarOutline,
        label: t('tutor.layout.calendarButton'),
    },
    {
        tab: 'materials',
        href: '/tutor/materials',
        icon: schoolOutline,
        label: t('tutor.layout.myMaterialsButton'),
    },
]);

const tutorStore = useTutorStore();
</script>

<template>
    <l-page>
        <ion-tabs v-if="isSmallSize">
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar slot="bottom">
                <ion-tab-button
                    v-for="item in menuItems"
                    :key="item.tab"
                    :tab="item.tab"
                    :href="item.href"
                    class="tab-container"
                >
                    <ion-icon :icon="item.icon" />
                    <span
                        v-if="item.tab === 'chats' && tutorStore.profile.chat.unreadCount"
                        class="badge"
                        >{{ tutorStore.profile.chat.unreadCount }}</span
                    >
                    <span>{{ item.label }}</span>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>

        <div v-else class="desktop">
            <ion-header>
                <ion-toolbar class="header">
                    <div slot="start">
                        <img src="/icons/logo.svg" class="logo" />
                    </div>
                    <div class="navbar ion-text-center">
                        <ion-button
                            v-for="item in menuItems"
                            :key="item.tab"
                            fill="clear"
                            :routerLink="item.href"
                            routerDirection="root"
                            routerLinkActive="active-link"
                            class="link"
                        >
                            {{ item.label }}
                        </ion-button>
                    </div>
                    <div slot="end" style="display: flex">
                        <l-language-switcher />
                    </div>
                </ion-toolbar>
            </ion-header>
            <ion-router-outlet></ion-router-outlet>
        </div>
    </l-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.desktop {
    ion-router-outlet {
        margin-top: 90px;
    }

    .logo {
        min-height: 44px;
    }

    .ion-toolbar {
        display: flex;
    }

    .link {
        /* --color: var(--ion-color-light); */
    }

    .active-link {
        /* --color: var(--ion-color-primary); */
    }
}

.tab-container {
    position: relative;
}

.badge {
    position: absolute;
    top: 3px;
    right: 10px;
    background-color: $purple-primary;
    color: $grey-100;
    border-radius: 30px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 15px;
    border: 1.5px solid $grey-100;
}
</style>
