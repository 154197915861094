import { client } from '@/core/api/client-proxy';
import {
    FormSchemaResult,
    GetFormResponseDto,
    RolesUsesFormT,
    SaveFormStepDto,
    SubmitFormDto,
    UpdateFormStepDto,
} from './form.dto';

class FormApi {
    basePath: RolesUsesFormT;

    constructor(basePath: RolesUsesFormT) {
        this.basePath = basePath;
    }

    async getFormSchema(code: string): Promise<FormSchemaResult> {
        const { data, error } = await client.GET(`/${this.basePath}/forms/{code}`, {
            params: { path: { code }, query: { join: ['latestResponse'] } },
        });

        return {
            success: !error,
            schema: data?.schema,
            latestResponse: data?.latestResponse,
        };
    }

    async getFormResponse({ code, uid }: GetFormResponseDto) {
        const res = await client.GET(`/${this.basePath}/forms/{code}/responses/{uid}`, {
            params: { path: { code, uid } },
        });
        return {
            success: !res.error,
            data: res.data,
        };
    }

    async saveFormStep({ code, content }: SaveFormStepDto) {
        const res = await client.POST(`/${this.basePath}/forms/{code}/responses/`, {
            params: { path: { code } },
            body: { content },
        });
        return {
            success: !res.error,
            data: res.data,
        };
    }

    async updateFormStep({ code, uid, content }: UpdateFormStepDto) {
        const res = await client.POST(`/${this.basePath}/forms/{code}/responses/{uid}`, {
            params: { path: { code, uid } },
            body: { content },
        });
        return {
            success: !res.error,
            data: res.data,
        };
    }

    async submitForm({ code, uid, content }: SubmitFormDto) {
        const res = await client.POST(`/${this.basePath}/forms/{code}/responses/{uid}/submit`, {
            params: { path: { code, uid } },
            body: { content },
        });
        return {
            success: !res.error,
            data: res.data,
        };
    }

    async uploadFile({ code, uid, file }: { code: string; uid: string; file: File }) {
        const formData = new FormData();
        formData.append('file', file);

        const res = await client.POST(`/${this.basePath}/forms/{code}/responses/{uid}/files/`, {
            params: { path: { code, uid } },
            body: formData as any,
        });

        return {
            success: !res.error,
            data: res.data,
        };
    }

    async deleteFiles({ code, uid, urls }) {
        const res = await client.DELETE(`/${this.basePath}/forms/{code}/responses/{uid}/files/`, {
            params: { path: { code, uid } },
            body: { urls },
        });

        return {
            success: !res.error,
            data: res.data,
        };
    }
}

export const useFormApi = (role: RolesUsesFormT) => new FormApi(role);
