import { format, Locale } from 'date-fns';
import { enUS, ru } from 'date-fns/locale'; // Подключите нужные вам локали
import { useI18n } from 'vue-i18n';

const localeMap: { [key: string]: Locale } = {
    en: enUS,
    ru: ru,
};

export function useLocaleDateFormat() {
    const { locale } = useI18n();

    return (date: Date | number, formatString: string) => {
        const currentLocale = localeMap[locale.value] || enUS;
        return format(date, formatString, { locale: currentLocale });
    };
}
