<script lang="ts" setup>
import { t } from '@/plugins/i18n';
import { ref, computed, onMounted } from 'vue';
import {
    IonIcon,
    IonFooter,
    IonContent,
    IonHeader,
    IonAlert,
    IonButtons,
    IonToolbar,
    IonText,
} from '@ionic/vue';
import LDateSelector from './LDateSelector.vue';
import LTimeSlots from './LTimeSlots.vue';
import { AvaliableSlot, GetAvaliableSlotsParams, useLearnerApi } from '../../api/learner.api';
import { useRouter } from 'vue-router';
import { closeOutline } from 'ionicons/icons';
import { Ref } from 'vue';
import LButton from '@/core/components/LButton.vue';

const selectedDate = ref(new Date());
const selectedTimeSlot = ref(null);
const router = useRouter();
const emit = defineEmits(['closeModal']);

const props = defineProps({
    tutorUserUid: {
        type: String,
        required: true,
    },
});

const availableSlots = ref([]) as Ref<AvaliableSlot[]>;
const isAlert = ref(false);
const alertButtons = ['Ok'];

const setOpenAlert = (state: boolean) => {
    isAlert.value = state;
};

const filteredSlots = computed(() => {
    return getAvailableSlotsForDate(selectedDate.value) as string[];
});

const fetchAvailableSlots = async (params: GetAvaliableSlotsParams) => {
    const learnerApi = useLearnerApi();
    const response = await learnerApi.getTutorAvailableSlots(params);
    if (response.success) {
        availableSlots.value = response.data!;
    }
};

const getAvailableSlotsForDate = (date: Date) => {
    return availableSlots.value
        .filter((slot) => new Date(slot.date).toDateString() === date.toDateString())
        .map((slot) => slot.startTime);
};

onMounted(() => {
    fetchAvailableSlots({
        startTime: new Date().toISOString(),
        endTime: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
        tutorUserUid: props.tutorUserUid,
    });
});

const updateSelectedDate = (date: Date) => {
    selectedDate.value = date;
};

const selectTimeSlot = (timeSlot: any) => {
    selectedTimeSlot.value = timeSlot;
};

const closeModal = () => {
    emit('closeModal');
};

const proceedBooking = async () => {
    if (selectedTimeSlot.value) {
        const selectedSlot = availableSlots.value.filter(
            (item) => item.startTime === selectedTimeSlot.value,
        )[0];
        router.push(
            `/trial-lesson-confirmation?uid=${selectedSlot.tutorUserUid}&start=${selectedSlot.startTime}&end=${selectedSlot.endTime}`,
        );
        closeModal();
    } else {
        setOpenAlert(true);
    }
};
</script>
<template>
    <ion-alert
        :is-open="isAlert"
        :header="t(`learner.booking.alertTitle`)"
        :buttons="alertButtons"
        @didDismiss="setOpenAlert(false)"
    ></ion-alert>
    <ion-header>
        <ion-toolbar>
            <ion-text>{{ t('learner.booking.title') }}</ion-text>
            <ion-buttons slot="end">
                <l-button @click="closeModal">
                    <ion-icon :icon="closeOutline"></ion-icon>
                </l-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <l-date-selector :selectedDate="selectedDate" @updateDate="updateSelectedDate" />
        <l-time-slots :availableSlots="filteredSlots" @selectTime="selectTimeSlot" />
    </ion-content>
    <ion-footer class="ion-padding">
        <l-button @click="proceedBooking">{{ t('learner.booking.submitButton') }} </l-button>
    </ion-footer>
</template>

<style scoped></style>
