<script setup lang="ts">
import { IonProgressBar } from '@ionic/vue';
import LPage from '@/core/components/LPage.vue';
import { reactive, Ref, ref } from 'vue';
import SpeedTest, { Results } from '@cloudflare/speedtest';
import LButton from '@/core/components/LButton.vue';
import { useI18n } from 'vue-i18n';

const isTesting = ref(false);
const isFinish = ref(false);
const progress = ref(0);
const logs = ref([]) as Ref<string[]>;
const { t } = useI18n();

const checkingFinishHandler = (results: Results) => {
    logs.value = [
        `getDownLoadedJitter: ${JSON.stringify(results.getDownLoadedJitter())}`,
        `getDownLoadedLatency: ${JSON.stringify(results.getDownLoadedLatency())}`,
        `getDownLoadedLatencyPoints: ${JSON.stringify(results.getDownLoadedLatencyPoints())}`,
        `getDownloadBandwidth: ${JSON.stringify(results.getDownloadBandwidth())}`,
        `getDownloadBandwidthPoints: ${JSON.stringify(results.getDownloadBandwidthPoints())}`,
        `getPacketLoss: ${JSON.stringify(results.getPacketLoss())}`,
        `getPacketLossDetails: ${JSON.stringify(results.getPacketLossDetails())}`,
        `getScores: ${JSON.stringify(results.getScores())}`,
        `getUnloadedJitter: ${JSON.stringify(results.getUnloadedJitter())}`,
        `getUnloadedLatency: ${JSON.stringify(results.getUnloadedLatency())}`,
        `getUnloadedLatencyPoints: ${JSON.stringify(results.getUnloadedLatencyPoints())}`,
        `getUploadBandwidth: ${JSON.stringify(results.getUploadBandwidth())}`,
        `getUploadBandwidthPoints: ${JSON.stringify(results.getUploadBandwidthPoints())}`,
        `getSummary: ${JSON.stringify(results.getSummary())}`,
    ];

    console.log('getDownLoadedJitter', results.getDownLoadedJitter());
    console.log('getDownLoadedLatency', results.getDownLoadedLatency());
    console.log('getDownLoadedLatencyPoints', results.getDownLoadedLatencyPoints());
    console.log('getDownloadBandwidth', results.getDownloadBandwidth());
    console.log('getDownloadBandwidthPoints', results.getDownloadBandwidthPoints());
    console.log('getPacketLoss', results.getPacketLoss());
    console.log('getPacketLossDetails', results.getPacketLossDetails());
    console.log('getScores', results.getScores());
    console.log('getUnloadedJitter', results.getUnloadedJitter());
    console.log('getUnloadedLatency', results.getUnloadedLatency());
    console.log('getUnloadedLatencyPoints', results.getUnloadedLatencyPoints());
    console.log('getUploadBandwidth', results.getUploadBandwidth());
    console.log('getUploadBandwidthPoints', results.getUploadBandwidthPoints());
    console.log('getSummary', results.getSummary());

    isTesting.value = false;
    isFinish.value = true;
};

const startChecking = () => {
    isTesting.value = true;

    const speedTest = new SpeedTest();

    speedTest.onFinish = checkingFinishHandler;
};
</script>

<template>
    <l-page>
        <div class="q-px-lg q-pt-lg q-pb-xs bg-white content-radius equipment-check">
            <div style="font-size: 24px; font-weight: 700">{{ t('tutor.deviceCheck.title') }}</div>
            <div v-if="!isFinish">
                <p>
                    {{ t('tutor.deviceCheck.description') }}
                </p>
            </div>

            <div v-if="!isTesting" class="row justify-center">
                <l-button
                    :label="t('tutor.deviceCheck.start')"
                    color="primary"
                    @click="startChecking"
                    >{{ t('tutor.deviceCheck.start') }}</l-button
                >
            </div>
            <div v-else-if="!isFinish">
                <ion-progress-bar type="indeterminate" />
            </div>

            <div v-if="isFinish" class="q-px-xs q-pt-xs">
                Результаты можно посмотреть в консоли
                <div v-for="log in logs">{{ log }}</div>
            </div>
        </div>

        <ion-progress-bar :value="progress"></ion-progress-bar>
    </l-page>
</template>
