import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { cancelReasonOptionsValues, rescheduleReasonOptionsValues } from '../api/calendar.api';

export function useCancelReasonOptions() {
    const { t } = useI18n();

    const cancelReasonOptions = computed(
        () =>
            [
                {
                    value: 'client_no_balance',
                    label: t('tutor.calendar.cancelReason.client_no_balance'),
                },
                {
                    value: 'client_request',
                    label: t('tutor.calendar.cancelReason.client_request'),
                },
                {
                    value: 'teacher_request',
                    label: t('tutor.calendar.cancelReason.teacher_request'),
                },
                {
                    value: 'client_technical_issue',
                    label: t('tutor.calendar.cancelReason.client_technical_issue'),
                },
                {
                    value: 'teacher_technical_issue',
                    label: t('tutor.calendar.cancelReason.teacher_technical_issue'),
                },
                {
                    value: 'scheduling_mistake',
                    label: t('tutor.calendar.cancelReason.scheduling_mistake'),
                },
            ] as Array<{ value: cancelReasonOptionsValues; label: string }>,
    );

    return { cancelReasonOptions };
}

export function useRescheduleReasonOptions() {
    const { t } = useI18n();

    const rescheduleReasonOptions = computed(
        () =>
            [
                {
                    value: 'client_no_balance',
                    label: t('tutor.calendar.rescheduleReason.client_no_balance'),
                },
                {
                    value: 'client_request',
                    label: t('tutor.calendar.rescheduleReason.client_request'),
                },
                {
                    value: 'teacher_request',
                    label: t('tutor.calendar.rescheduleReason.teacher_request'),
                },
                {
                    value: 'client_technical_issue',
                    label: t('tutor.calendar.rescheduleReason.client_technical_issue'),
                },
                {
                    value: 'teacher_technical_issue',
                    label: t('tutor.calendar.rescheduleReason.teacher_technical_issue'),
                },
                {
                    value: 'scheduling_mistake',
                    label: t('tutor.calendar.rescheduleReason.scheduling_mistake'),
                },
            ] as Array<{ value: rescheduleReasonOptionsValues; label: string }>,
    );

    return { rescheduleReasonOptions };
}
