<script setup lang="ts">
import { computed, onMounted, reactive, ref, Ref, watch } from 'vue';
import {
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonRadio,
    IonButton,
    IonInput,
    IonCardTitle,
    IonCardContent,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    RadioGroupChangeEventDetail,
} from '@ionic/vue';
import { getHHMMFromDate, getYYYYMMDDFromDate } from '@common/utils/date-formatters';
import { addDays } from 'date-fns';
import { AvailableSlot, calendarApi, rescheduleReasonOptionsValues } from '../../api/calendar.api';
import { fromLocalToZoned, fromZonedToLocal } from '@/core/utils/date-formatters';
import { THIRTY_MINUTES_MS } from '@common/constants/constant';
import { t } from '@/plugins/i18n';
import { useRescheduleReasonOptions } from '../../utils/cancel-reason-options';
import LButton from '@/core/components/LButton.vue';
import LSelect from '@/core/components/LSelect.vue';

const emit = defineEmits(['ok']);

const props = defineProps({
    event: {
        type: Object,
        required: true,
    },
    timezone: {
        type: String,
        required: true,
    },
    newDateTime: {
        type: Date,
    },
});

const availableSlots = reactive([]) as AvailableSlot[];
const possibleTransferDates = reactive([]) as { date: string }[];
const eventDurationMinutes = ref(30) as Ref<30 | 60>;
const initialEventDurationMinutes = ref(30) as Ref<30 | 60>;
const newStartDate = ref('');
const newStartTime = ref('');

const possibleTimes = computed(() => {
    const times = availableSlots
        .filter((slot) => {
            const startDate = new Date(slot.startTime as string);
            const slotDate = getYYYYMMDDFromDate(startDate);
            return slotDate === newStartDate.value.replaceAll('/', '-');
        })
        .map((slot) => new Date(slot.startTime as string).getHours());
    return times;
});

const is60MinutesDurationPossible = computed(() => {
    const newStartDateTime = new Date(newStartDate.value.replaceAll('/', '-'));
    const [h, m] = newStartTime.value.split(':').map(Number);
    newStartDateTime.setHours(h, m, 0);

    return !!availableSlots.find((slot: any) => {
        return (
            new Date(slot.startTime).getTime() === newStartDateTime.getTime() + THIRTY_MINUTES_MS
        );
    });
});

watch(newStartTime, () => {
    if (!is60MinutesDurationPossible.value) {
        eventDurationMinutes.value = 30;
    } else {
        eventDurationMinutes.value = initialEventDurationMinutes.value;
    }
});

const transferReason = ref('client_request') as Ref<rescheduleReasonOptionsValues>;
const reasonDescription = ref('');
const isTransferRecurrent = ref(false);

const { rescheduleReasonOptions } = useRescheduleReasonOptions();
const loadAvailableSlots = async () => {
    availableSlots.splice(0);

    const rangeStartDate = fromZonedToLocal(new Date(new Date()), props.timezone);
    const rangeEndDate = fromZonedToLocal(addDays(new Date(), 28), props.timezone);

    const result = await calendarApi.getAvailableSlots({
        startTime: rangeStartDate.toISOString(),
        endTime: rangeEndDate.toISOString(),
    });

    const tzConvertedData = result.data.map((slot) => {
        const startDate = fromLocalToZoned(new Date(slot.startTime as string), props.timezone);
        const endDate = fromLocalToZoned(new Date(slot.endTime as string), props.timezone);
        const date = startDate.toISOString().split('T')[0];

        return {
            ...slot,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            date,
        };
    });

    availableSlots.push(...tzConvertedData);
};

const onSubmit = async () => {
    const newStartDateTime = new Date(newStartDate.value.replaceAll('/', '-'));
    const [h, m] = newStartTime.value.split(':').map(Number);
    newStartDateTime.setHours(h, m, 0);

    const newEndDateTime = new Date(
        newStartDateTime.getTime() + eventDurationMinutes.value * 60000,
    );

    const body = {
        currentStartTime: fromZonedToLocal(
            new Date(props.event.tutorEvent.startTime),
            props.timezone,
        ).toISOString(),
        reasonType: transferReason.value,
        reasonDescription: reasonDescription.value,
        isRescheduleRecurrent: isTransferRecurrent.value,
        newStartTime: fromZonedToLocal(newStartDateTime, props.timezone).toISOString(),
        newEndTime: fromZonedToLocal(newEndDateTime, props.timezone).toISOString(),
    };

    await calendarApi.transferEvent(props.event.tutorEvent.uid, body);
    emit('ok');
};

const fetchData = async () => {
    if (props.newDateTime) {
        newStartDate.value = getYYYYMMDDFromDate(props.newDateTime).replaceAll('-', '/');
        newStartTime.value = getHHMMFromDate(props.newDateTime);
    }

    await loadAvailableSlots();
    initialEventDurationMinutes.value = Math.round(
        (new Date(props.event.tutorEvent.endTime).getTime() -
            new Date(props.event.tutorEvent.startTime).getTime()) /
            60000,
    ) as 30 | 60;

    eventDurationMinutes.value = initialEventDurationMinutes.value;
    possibleTransferDates.splice(0);
    possibleTransferDates.push(
        ...availableSlots.map((sl) => ({
            date: getYYYYMMDDFromDate(new Date(sl.startTime as string)),
        })),
    );
};

const getDataTimeString = computed(
    () => `${newStartDate.value.replaceAll('/', '-')}T${newStartTime.value}`,
);
const onChangeDateTime = (event) => {
    const changedDateTime = new Date(event.detail.value);
    newStartDate.value = getYYYYMMDDFromDate(changedDateTime).replaceAll('-', '/');
    newStartTime.value = getHHMMFromDate(changedDateTime);
};

onMounted(fetchData);
</script>

<template>
    <ion-card-content>
        <ion-datetime-button datetime="datetime"></ion-datetime-button>
        <ion-modal :keep-contents-mounted="true">
            <ion-datetime
                id="datetime"
                :hour-values="possibleTimes"
                :minute-values="[0, 30]"
                presentation="date-time"
                @ion-change="onChangeDateTime"
                v-model="getDataTimeString"
            ></ion-datetime>
        </ion-modal>
        <ion-card-title>{{ t('tutor.calendar.transfer.timeTitle') }}</ion-card-title>
        <l-select
            v-model="transferReason"
            :label="t('tutor.calendar.transfer.transferReason')"
            :options="rescheduleReasonOptions"
        >
        </l-select>
        <ion-input
            fill="outline"
            :placeholder="t('tutor.calendar.transfer.transferComment')"
            v-model="reasonDescription"
        />
        <l-button @click="onSubmit">{{ t('tutor.calendar.transfer.transferButton') }}</l-button>
    </ion-card-content>
</template>

<style scoped>
/* Custom styling if needed */
</style>
