import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

export const setupSentry = (app: App, router: Router) => {
    const environment: string = import.meta.env.VITE_ENV || 'production';

    Sentry.init({
        enabled: !environment.includes('local'),
        app,
        environment,
        dsn: 'https://b7681ba9433ef950bfc9ae7e7f84af90@o4508448437633024.ingest.de.sentry.io/4508448647938128',
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'https://api.dev1.learnlink.team',
            'https://api.dev2.learnlink.team',
            'https://api.dev3.learnlink.team',
            'https://api.qa1.learnlink.team',
            'https://api.qa2.learnlink.team',
            'https://api.stage.learnlink.team',
            // TODO: production api?
        ],
        // Session Replay
        replaysSessionSampleRate: environment === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
