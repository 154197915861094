import SurveyDemoPage from '@/pages/SurveyDemoPage.vue';
import UiKitPage from '@/pages/UiKitPage.vue';
import { RouteRecordRaw } from 'vue-router';

export const demoRoutes: RouteRecordRaw[] = [
    {
        path: '/ui-kit',
        name: 'UiKit',
        component: UiKitPage,
    },
    {
        path: '/survey',
        name: 'SurveyDemoPage',
        component: SurveyDemoPage,
    },
];
