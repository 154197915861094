import { Ref } from 'vue';
import { useFormApi } from '../api/form.api';
import { ILatestResponse } from '../api/form.dto';

// eslint-disable-next-line no-unused-vars
type OnCompleteType = (status: string, data?: any) => any;

export function useFormHandlers(
    role: 'learner' | 'tutor',
    latestResponse: Ref<ILatestResponse | null | undefined>,
    code: string,
) {
    const formApi = useFormApi(role);

    const onUploadFiles = async (sender: any, files: File[], onComplete: OnCompleteType) => {
        if (!latestResponse.value?.uid) {
            console.error('No UID found for the latest response');
            onComplete('error');
            return;
        }
        try {
            const uploadPromises = files.map((file) =>
                formApi.uploadFile({
                    file,
                    code,
                    uid: latestResponse.value!.uid,
                }),
            );
            const results = await Promise.all(uploadPromises);
            const allSuccess = results.every(({ success }) => success);

            if (!allSuccess) throw new Error('One or more files failed to upload');

            const uploadedData = results.map((result, index) => ({
                file: files[index],
                content: result.data?.url,
            }));

            onComplete('success', uploadedData);

            await formApi.saveFormStep({
                code,
                content: JSON.stringify({ ...sender.getData() }),
            });
        } catch (error) {
            console.error('Error uploading files:', error);
            onComplete('error');
        }
    };

    const onClearFiles = async (sender: any, urls: string[], onComplete: OnCompleteType) => {
        if (!latestResponse.value?.uid) {
            console.error('No UID found for the latest response');
            onComplete('error');
            return;
        }
        try {
            const { success } = await formApi.deleteFiles({
                code,
                uid: latestResponse.value.uid,
                urls,
            });
            if (!success) throw new Error('Failed to clear files');
            onComplete('success');
            await formApi.saveFormStep({
                code,
                content: JSON.stringify(sender.getData()),
            });
        } catch (error) {
            console.error('Error clearing files:', error);
            onComplete('error');
        }
    };

    return { onUploadFiles, onClearFiles };
}
