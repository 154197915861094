<script setup lang="ts">
import 'survey-core/defaultV2.min.css';
import { Model, SurveyModel } from 'survey-core';
import { SurveyComponent } from 'survey-vue3-ui';
import { onMounted } from 'vue';
import surveyJson from './survey.json';

const alertResults = (sender: SurveyModel) => {
    localStorage.setItem('formData', JSON.stringify(sender.data));
};

const onNextClick = async (sender: SurveyModel, options: any) => {
    // options.complete() - должен быть вызван для завершения асинхронной валидации
    // options.errors - можно добавить ошибки, чтобы предотвратить переход на следующую страницу
    const { data, currentPageNo } = sender;
    setTimeout(async () => {
        localStorage.setItem('formData', JSON.stringify({ ...data, page: currentPageNo + 1 }));
        options.complete();
    }, 2000);
};

const survey = new Model(surveyJson);
survey.onComplete.add(alertResults);
survey.onServerValidateQuestions.add(onNextClick);

onMounted(() => {
    const data = JSON.parse(localStorage.getItem('formData') || '{}');
    survey.currentPage = data?.page || 0;
    survey.data = data || {};
});
</script>

<template>
    <!--    <ion-card>-->
    <SurveyComponent :model="survey" />
    <!--    </ion-card>-->
</template>
