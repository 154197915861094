<script setup lang="ts">
import LButton from '@/core/components/LButton.vue';
import LSelect from '@/core/components/LSelect.vue';
import { fromZonedToLocal } from '@/core/utils/date-formatters';
import { t } from '@/plugins/i18n';
import { THIRTY_MINUTES_MS } from '@common/constants/constant';
import { IonCardContent, IonLabel, IonRadio, IonRadioGroup, IonText } from '@ionic/vue';
import { onMounted, reactive, ref, Ref } from 'vue';
import { calendarApi, ScheduleEventDto } from '../../api/calendar.api';
import { tutorApi } from '../../api/tutor.api';

const emit = defineEmits(['ok']);

const props = defineProps({
    startDateTime: {
        type: Date,
        required: true,
    },
    availableSlots: {
        type: Array,
        required: true,
    },
    timezone: {
        type: String,
        required: true,
    },
});

const eventDurationMinutes = ref(30);
const eventType = ref('lesson');
const isEventRecurrent = ref(false);
const tutoringOptions = reactive([]) as Array<{ value: string; label: string }>;
// todo add type
const choosedTutoringOption = ref(null) as Ref<null | any>;

const is60MinutesDurationPossible = !!props.availableSlots.find((slot: any) => {
    return new Date(slot.startTime).getTime() === props.startDateTime.getTime() + THIRTY_MINUTES_MS;
});

const eventTypeOptions = [
    { label: 'lesson', value: 'lesson' },
    { label: 'unavailable', value: 'unavailable' },
];
const onSubmit = async () => {
    const eventEndDateTime = new Date(
        props.startDateTime.getTime() + eventDurationMinutes.value * 60000,
    );
    const body = {
        learnerUserUid:
            eventType.value === 'lesson' ? choosedTutoringOption.value.learnerUserUid : '',
        endTime: fromZonedToLocal(eventEndDateTime, props.timezone).toISOString(),
        isRecurrent: eventType.value === 'lesson' ? isEventRecurrent.value : false,
        startTime: fromZonedToLocal(props.startDateTime, props.timezone).toISOString(),
        type: eventType.value as any,
    };

    await calendarApi.scheduleEvent(body as ScheduleEventDto);
    emit('ok');
};

const loadTutorings = async () => {
    tutoringOptions.splice(0);

    const tutoringsResponse = await tutorApi.getTutorings();

    tutoringOptions.push(
        ...tutoringsResponse?.map((tutoring) => {
            return {
                value: tutoring.learner.firstName,
                label: `${tutoring.learner?.firstName}`,
            };
        }),
    );
};
onMounted(loadTutorings);
</script>

<template>
    <ion-card-content>
        <b>{{ t('tutor.calendar.schedule.startDate') }}</b>
        <div class="ion-padding">
            {{ props.startDateTime.toLocaleString() }}
        </div>
        <div>
            <ion-label>{{ t('tutor.calendar.schedule.eventDuration') }}</ion-label>
        </div>
        <ion-radio-group v-model="eventDurationMinutes">
            <ion-radio label-placement="end" class="ion-padding" :value="30"
                >30 {{ t('tutor.calendar.schedule.minutes') }}</ion-radio
            >
            <ion-radio label-placement="end" :value="60" :disabled="!is60MinutesDurationPossible"
                >60 {{ t('tutor.calendar.schedule.minutes') }}</ion-radio
            ><br />
        </ion-radio-group>

        <ion-label>{{ t('tutor.calendar.schedule.eventType') }}</ion-label>
        <l-select
            v-model="eventType"
            :placeholder="t('tutor.calendar.schedule.eventType')"
            :options="eventTypeOptions"
        >
        </l-select>

        <div v-if="eventType === 'lesson'">
            <ion-label>{{ t('tutor.calendar.schedule.tutoring') }}</ion-label>
            <l-select
                v-model="choosedTutoringOption"
                :placeholder="t('tutor.calendar.schedule.selectTutoring')"
                :options="tutoringOptions"
            >
            </l-select>

            <ion-text>{{ t('tutor.calendar.schedule.recurrency') }}</ion-text>
            <div>
                <ion-radio-group :value="isEventRecurrent">
                    <ion-radio label-placement="end" class="ion-padding" :value="false">{{
                        t('tutor.calendar.schedule.oneTime')
                    }}</ion-radio>
                    <ion-radio label-placement="end" :value="true">{{
                        t('tutor.calendar.schedule.permanent')
                    }}</ion-radio>
                    <br />
                </ion-radio-group>
            </div>
        </div>

        <l-button @click="onSubmit">{{ t('tutor.calendar.schedule.createButton') }}</l-button>
    </ion-card-content>
</template>

<style scoped></style>
