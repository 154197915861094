import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export function fromLocalToZoned(date: Date, timezone: string) {
    return toZonedTime(
        fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone),
        timezone,
    );
}

export function fromZonedToLocal(date: Date, timezone: string) {
    return fromZonedTime(
        toZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone),
        timezone,
    );
}
