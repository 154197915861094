import type { RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { AUTH_ROUTES } from './auth.guard';

export const alreadyLoggedInGuard = async (to: RouteLocationNormalized) => {
    const authStore = useAuthStore();
    // if we are authenticated and trying to access a auth specific route, redirect to home
    if (authStore.isAuthenticated && AUTH_ROUTES.includes(to.path)) {
        return { path: '/' };
    }
    return true;
};
