<script lang="ts" setup>
import { IonButton, IonIcon } from '@ionic/vue';
import { ref, computed } from 'vue';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';

const format = useLocaleDateFormat();
const props = defineProps({
    selectedDate: {
        type: Date,
        required: true,
    },
});
const emit = defineEmits(['updateDate']);

const currentWeekStart = ref(new Date()); // Start of the current week

const weekDays = computed(() => {
    const days = [] as Date[];
    for (let i = 0; i < 7; i++) {
        const day = new Date(currentWeekStart.value);
        day.setDate(currentWeekStart.value.getDate() + i);
        days.push(day);
    }
    return days;
});

const formattedWeek = computed(() => {
    const start = format(weekDays.value[0], 'd MMMM');
    const end = format(weekDays.value[6], 'd MMMM');

    return `${start} - ${end}`;
});

const prevWeek = () => {
    const newStart = new Date(currentWeekStart.value);
    newStart.setDate(currentWeekStart.value.getDate() - 7);
    currentWeekStart.value = newStart;
};

const nextWeek = () => {
    const newStart = new Date(currentWeekStart.value);
    newStart.setDate(currentWeekStart.value.getDate() + 7);
    currentWeekStart.value = newStart;
};

const selectDay = (day: Date) => {
    emit('updateDate', day);
};

const isSelected = (day: Date) => {
    return day.toDateString() === props.selectedDate.toDateString();
};
</script>

<template>
    <div class="l-date-selector">
        <div class="week-selector">
            <l-button @click="prevWeek" fill="outline" color="tertiary">
                <ion-icon :icon="chevronBackOutline" color="dark"
            /></l-button>
            <l-button fill="clear">{{ formattedWeek }}</l-button>
            <l-button @click="nextWeek" fill="outline" color="tertiary">
                <ion-icon :icon="chevronForwardOutline" color="dark" />
            </l-button>
        </div>
        <br />

        <div class="week-days-container">
            <div
                v-for="day in weekDays"
                size="small"
                :key="day.toISOString()"
                fill="outline"
                :class="isSelected(day) ? 'selected-day' : ' '"
                @click="selectDay(day)"
            >
                <div class="week-day selected">
                    <div class="day caption-3">
                        {{ format(day, 'EEEEEE') }}
                    </div>
                    <div class="caption-6">
                        {{ format(day, 'd.MM') }}
                    </div>
                </div>
            </div>
        </div>
        <br />
    </div>
</template>

<style scoped>
.l-date-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.week-days-container {
    height: 50px;
    background-color: #e2e1e5;
    border-radius: 8px;
    display: flex;
}

.selected-day {
    border-radius: 8px;
    border: 2px #000 solid;
}

.week-selector {
    display: flex;
}

.week-day {
    .day {
        text-align: center;
    }

    .caption-6 {
        font-size: 10px;
        text-align: center;
    }

    width: 50px;
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
}
</style>
