export const API_URL = getApiUrl();

/*
 * Temporary solution for DEV
 * Get API URL based on the environment
 */
function getApiUrl() {
    const apiUrl = import.meta.env.VITE_API_URL as string;
    if (apiUrl) {
        return apiUrl;
    }
    if (typeof window !== 'undefined') {
        if (window.location.hostname === 'localhost') {
            return 'http://localhost:3001';
        }
        if (window.location.hostname.startsWith('local-app.')) {
            return `https://${window.location.hostname.replace('local-app.', 'api.')}`;
        }
        if (window.location.hostname.startsWith('local.')) {
            return `https://${window.location.hostname.replace('local.', 'api.')}`;
        }
        if (window.location.hostname.startsWith('app.')) {
            return `https://${window.location.hostname.replace('app.', 'api.')}`;
        }
    }
    return '';
}
