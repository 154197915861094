import { App } from 'vue';

import { SharedWorkerPonyfill, SharedWorkerSupported } from '@okikio/sharedworker';
import LearnLinkServiceWorker from '@/core/workers/learnlink.service-worker?worker&url';
import LearnLinkWorkerMobile from '@/core/workers/learnlink.shared-worker?worker';
import LearnLinkWorkerDesktop from '@/core/workers/learnlink.shared-worker?sharedworker';
import { createSharedWorkerSubscription } from '@/modules/chat/utils/chat.utils';

export const setupWorker = (app: App) => {
    // do nothing
};

function initializeSharedWorker() {
    console.log('Initialize shared worker');

    let worker: SharedWorkerPonyfill;

    if (SharedWorkerSupported) {
        worker = new SharedWorkerPonyfill(new LearnLinkWorkerDesktop());
    } else {
        worker = new SharedWorkerPonyfill(new LearnLinkWorkerMobile());
    }

    return worker;
}

export const sharedWorker = initializeSharedWorker();

createSharedWorkerSubscription();

async function initializeServiceWorker() {
    try {
        console.log('Register service worker');

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', async () => {
                try {
                    console.log('register worker url', LearnLinkServiceWorker);
                    const registration = await navigator.serviceWorker.register(
                        LearnLinkServiceWorker,
                        { type: 'module' },
                    );
                    console.log('Service Worker registered:', registration);
                } catch (error) {
                    console.error('Failed to register Service Worker:', error);
                }
            });
        }
    } catch (error) {
        console.log('Failed register worker: ', error);
    }
}

initializeServiceWorker();
