<script setup lang="ts">
import { IonContent, IonPage } from '@ionic/vue';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LLoginForm from '../components/LLoginForm.vue';
import LPage from '@/core/components/LPage.vue';
const { isSmallSize } = useScreenSize();
</script>

<template>
    <l-page v-if="isSmallSize">
        <l-login-form />
    </l-page>
    <ion-page v-else>
        <ion-content>
            <div class="form-container">
                <l-login-form class="form" />
            </div>
        </ion-content>
    </ion-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.purple-primary {
    color: var(--ion-color-success);
}

.form {
    width: 50%;
    min-height: 100dvh;
    background-color: $grey-100;
}

.form-container {
    width: 100%;
    background-color: $purple-light;
}
</style>
