<script setup lang="ts">
import { IonTabBar, IonTabButton, IonIcon, IonTabs, IonPage, IonRouterOutlet } from '@ionic/vue';

import { homeOutline, searchOutline, chatbubbleOutline, calendarOutline } from 'ionicons/icons';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { t } from '@/plugins/i18n';
import { useLearnerStore } from '../store/learner.store';

const { isSmallSize } = useScreenSize();

const learnerStore = useLearnerStore();
</script>

<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar :slot="isSmallSize ? 'bottom' : 'top'">
                <ion-tab-button tab="home" href="/learner/home">
                    <ion-icon :icon="homeOutline" />
                    <span>{{ t('learner.layout.homeButton') }}</span>
                </ion-tab-button>
                <ion-tab-button tab="search" href="/learner/catalog">
                    <ion-icon :icon="searchOutline" />
                    <span>{{ t('learner.layout.searchButton') }}</span>
                </ion-tab-button>
                <ion-tab-button tab="chats" href="/learner/chats">
                    <ion-icon :icon="chatbubbleOutline" />
                    <span class="badge" v-if="learnerStore.profile.chat?.unreadCount">{{
                        learnerStore.profile.chat.unreadCount
                    }}</span>
                    <span>{{ t('learner.layout.chatsButton') }}</span>
                </ion-tab-button>
                <ion-tab-button tab="calendar" href="/learner/calendar">
                    <ion-icon :icon="calendarOutline" />
                    <span>{{ t('learner.layout.calendarButton') }}</span>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.badge {
    position: absolute;
    top: 3px;
    right: 50px;
    background-color: $purple-primary;
    color: $grey-100;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 15px;
    border: 1.5px solid $grey-100;
}
</style>
