<script setup lang="ts">
import {
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCheckbox,
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import LPage from '@/core/components/LPage.vue';
import LButton from '@/core/components/LButton.vue';

const authStore = useAuthStore();
const router = useRouter();

const onLogout = async () => {
    await authStore.logout();
    router.push('/login');
};
</script>

<template>
    <l-page>
        <ion-header collapse="condense">
            <ion-toolbar>
                <ion-title size="large">Blank</ion-title>
            </ion-toolbar>
        </ion-header>

        <div>
            <p>Auth isAuthenticated: {{ authStore.isAuthenticated }}</p>
            <p>User email: {{ authStore.user.email }}</p>
            <p>User uid: {{ authStore.user.uid }}</p>
        </div>

        <l-button color="warning" @click="onLogout"> Logout </l-button>
    </l-page>
</template>

<style scoped>
#container {
    text-align: center;

    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;

    color: #8c8c8c;

    margin: 0;
}

#container a {
    text-decoration: none;
}

.checkbox-wrapper {
    justify-content: start;
}
</style>
