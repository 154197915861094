import type { RouteRecordRaw } from 'vue-router';
import { commonRoutes } from './common.routes';
import { demoRoutes } from './demo.routes';
import { learnerRoutes } from './learner.routes';
import { tutorRoutes } from './tutor.routes';

export const routes: RouteRecordRaw[] = [
    ...commonRoutes,
    ...learnerRoutes,
    ...tutorRoutes,
    ...demoRoutes,
];
