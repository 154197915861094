import { useAuthStore } from '@/modules/auth/store/auth.store';
import { defineStore } from 'pinia';
import { Chat, ChatMessage, ChatMessagesMeta, useChatApi } from '../api/chat.api';
import { sendMessageToShw } from '../utils/chat.utils';
import { useTutorStore } from '@/modules/tutor/store/tutor.store';
import { useLearnerStore } from '@/modules/learner/store/learner.store';

export type CustomChat = Chat & ChatMeta;

type ChatMeta = {
    isParticipantTyping?: boolean;
    chatMessagesMeta?: ChatMessagesMeta;
};

export type CustomChatMessage = ChatMessage & ChatMessageMeta;

type ChatMessageMeta = {
    isMessageReceivedByServer?: boolean;
    isMessageDelivered?: boolean;
};

let typingTimeout: NodeJS.Timeout | null = null;

// TODO: refactor on setup func
export const useChatStore = defineStore('chatStore', {
    state: () => ({
        chats: [] as CustomChat[],
        activeChat: null as null | CustomChat,
        activeChatMessages: [] as CustomChatMessage[],
        loading: false,
    }),

    actions: {
        async fetchChats() {
            const user = useAuthStore().user;

            this.loading = true;
            const chatApi = useChatApi(user.role as 'tutor' | 'learner');

            try {
                const chats = await chatApi.getChats();

                this.chats = chats;
            } catch (error) {
                console.error('Failed to fetch chats:', error);
            } finally {
                this.loading = false;
            }
        },
        async setActiveChat(chatUid: string) {
            if (!this.chats.length) {
                await this.fetchChats();
            }

            this.activeChat = this.chats.find((chat) => chat.uid === chatUid) || null;

            if (this.activeChat) {
                this.activeChat.latestReadMessageUid ??= '';
                this.activeChat.otherParticipant.latestReadMessageUid ??= '';

                this.activeChatMessages = [];

                await this.loadActiveChatMessages();
            }
        },
        setChatParticipantTyping(chatUid: string) {
            const chat = this.chats.find((chat) => chat.uid === chatUid) || null;

            if (chat) {
                if (typingTimeout) {
                    clearTimeout(typingTimeout);
                }

                chat.isParticipantTyping = true;

                typingTimeout = setTimeout(() => {
                    chat.isParticipantTyping = false;
                }, 5000);
            }
        },
        setActiveChatMessageRead(messageUid: string) {
            const user = useAuthStore().user;

            if (this.activeChat && messageUid > (this.activeChat.latestReadMessageUid as string)) {
                const prevLatestReadMessageUid = this.activeChat.latestReadMessageUid;
                const prevReadedMessageIndex = this.activeChatMessages.findIndex(
                    (msg) => msg.uid === prevLatestReadMessageUid,
                );
                let prevUnreadMessagesCount = 0;
                if (prevReadedMessageIndex !== -1) {
                    for (let i = 0; i < prevReadedMessageIndex; i += 1) {
                        if (this.activeChatMessages[i].senderUid !== user.uid) {
                            prevUnreadMessagesCount += 1;
                        }
                    }
                }

                this.activeChat.latestReadMessageUid = messageUid;
                sendMessageToShw({
                    type: 'mark_as_read',
                    chatUid: this.activeChat?.uid,
                    messageUid: messageUid,
                });

                const messageIndex = this.activeChatMessages.findIndex(
                    (msg) => msg.uid === messageUid,
                );
                let unreadMessagesCount = 0;
                if (messageIndex !== -1) {
                    for (let i = 0; i < messageIndex; i += 1) {
                        if (this.activeChatMessages[i].senderUid !== user.uid) {
                            unreadMessagesCount += 1;
                        }
                    }
                    this.activeChat.unreadCount = unreadMessagesCount;
                }

                const authStore = useAuthStore();
                const userStore =
                    authStore.user.role === 'tutor' ? useTutorStore() : useLearnerStore();
                userStore.profile.chat.unreadCount -= prevUnreadMessagesCount - unreadMessagesCount;
            }
        },
        async loadActiveChatMessages(cursorMessageUid?: string) {
            const user = useAuthStore().user;

            const chatApi = useChatApi(user.role as 'tutor' | 'learner');

            const messagesResponse = await chatApi.getChatMessages(
                this.activeChat?.uid!,
                cursorMessageUid,
            );

            this.activeChatMessages.push(
                ...messagesResponse.data.map((data) => {
                    return {
                        isMessageDelivered: true,
                        isMessageReceivedByServer: true,
                        ...data,
                    };
                }),
            );

            this.activeChat!.chatMessagesMeta = messagesResponse.meta;
        },
        addMessageToActiveChat(message: CustomChatMessage) {
            if (this.activeChat?.uid === message.chatUid) {
                this.unsetParticipantTyping();
                this.activeChatMessages.unshift(message);
            }
        },
        unsetParticipantTyping() {
            if (this.activeChat) {
                this.activeChat.isParticipantTyping = false;
                if (typingTimeout) {
                    clearTimeout(typingTimeout);
                }
            }
        },
    },
});
